import React from "react";
import { Link } from "react-router";

const Header = () => {
  const toggleMenu = () => {
    const menu = document.querySelector(".categories_menu");
    const mobileNav = document.getElementById("nav-links-mobile");
    const isMobile = window.matchMedia("(max-width: 991px)");
    if (!isMobile.matches) return;
    if (menu.style.display === "block") {
      menu.style.display = "none";
    }
    mobileNav.style.display =
      mobileNav.style.display === "block" ? "none" : "block";
  };

  const toggleCategory = () => {
    const menu = document.querySelector(".categories_menu");
    const mobileNav = document.getElementById("nav-links-mobile");
    const isMobile = window.matchMedia("(max-width: 991px)");
    if (!isMobile.matches) return;
    if (mobileNav.style.display === "block") {
      mobileNav.style.display = "none";
    }
    menu.style.display = menu.style.display === "block" ? "none" : "block";
  };

  const navigating = (e) => {
    e.stopPropagation();
    const mobileNav = document.getElementById("nav-links-mobile");
    mobileNav.style.display =
      mobileNav.style.display === "block" ? "none" : "block";
  };

  const navigateToHome = () => {
    const isMobile = window.matchMedia("(max-width: 991px)");

    if (isMobile.matches) {
      const menu = document.querySelector(".categories_menu");
      const mobileNav = document.getElementById("nav-links-mobile");

      mobileNav.style.display = "none";
      menu.style.display = "none";
    }
  };

  return (
    <section class="header_section">
      <div class="container">
        <header>
          <div class="logo">
            <Link onClick={navigateToHome} to={"/"}>
              <img
                src={require("../assets/images/main_logo.svg").default}
                alt="main_logo"
              />
            </Link>
          </div>
          <div class="categories">
            <a href="#" onClick={toggleCategory}>
              Categories
            </a>
            <div class="categories_menu">
              <a href="#">categiories_one</a>
              <a href="#">categiories_one</a>
              <a href="#">categiories_one</a>
            </div>
          </div>
          <div class="search-bar">
            <input type="text" placeholder="Search for anything" />
            <img
              src={require("../assets/images/search_icon.svg").default}
              alt="search_icon"
            />
          </div>
          <div onClick={toggleMenu} class="hamburger" id="hamburger">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <nav class="nav-links">
            <Link to={"/online/english-tutors"}>Find tutors</Link>
            <Link to={"/teach"}>Become a tutor</Link>
            <div class="language-selector">
              <select>
                <option value="en">English, AED</option>
                <option value="es">Español, EUR</option>
              </select>
              <div class="down_arrow">
                <img
                  src={require("../assets/images/down_arrow.svg").default}
                  alt="down_arrow"
                />
              </div>
            </div>
            {/* <div class="language_selector">
              <span>English, AED</span><img src=""/>
            </div> */}
            <div class="cart">
              <Link to={"/cart"}>
                <img
                  src={require("../assets/images/cart_icon.svg").default}
                  alt="cart_icon"
                />
              </Link>
            </div>
            <Link class="login" to={"/login"}>
              <img
                src={require("../assets/images/login_icon.svg").default}
                alt=""
              />
              Log In
            </Link>
          </nav>
          <nav class="nav-links-mobile" id="nav-links-mobile">
            <div class="mobile_wrap_container">
              <Link onClick={navigating} to={"/find-tutors"}>
                Find tutors
              </Link>
              <Link onClick={navigating} to={"/become-tutor"}>
                Become a tutor
              </Link>
              <div class="search-bar_mb">
                <input type="text" placeholder="Search for anything" />
                <img
                  src={require("../assets/images/search_icon.svg").default}
                  alt="search_icon"
                />
              </div>
              <div class="language-selector">
                <select>
                  <option value="en">English, AED</option>
                  <option value="es">Español, EUR</option>
                </select>
              </div>
              <div class="login_btn">
                <Link class="login" onClick={navigating} to={"/login"}>
                  {/* <a href="#" class="login"> */}
                  Log In
                  {/* </a> */}
                </Link>
              </div>
            </div>
          </nav>
        </header>
      </div>
    </section>
  );
};

export default Header;
