import React, { useState } from "react";
import { Arrowdown, Arrowup } from "../utils/Icons";
import { Link } from "react-router";

const accordianData = [
  {
    title: "I am a beginner, is Preply suitable for me?",
    description:
      "Yes, absolutely! Our platform provides an extensive selection of experienced tutors, each with expertise in working with beginners and learners across all levels.",
  },
  {
    title: "How do I choose a tutor? ",
    description:
      "Find a tutor by using the filters on our search page. These allow you to browse tutors who match your preferences, including factors like price, availability, and qualifications. ",
  },
  {
    title: "How does trial lessons work?",
    description:
      "Trials are 25 or 50-min live lessons that let you meet a tutor to decide if you’d like to continue learning with them. Most tutors use these lessons to clarify your goals and give you a taste of their teaching style. ",
  },
  {
    title: "What happens after my trial?",
    description:
      "If you like your tutor, you’ll choose a subscription plan based on how many hours you’d like to study each week. Our flexible subscriptions can be paused, canceled or updated at any time.",
  },
  {
    title: "What happens if I am not satisfied with my trial lesson?",
    description:
      "If your trial lesson doesn't live up to your expectations, no problem at all! You can switch to another tutor for free or opt for a full refund – it's all about making sure you're happy with your learning experience.",
  },
  {
    title: "How does the subscription model work",
    description:
      "The subscription model allows students to choose how many lessons they want to take with a tutor each week and pay for their classes with a recurring payment every 28 days. Students are offered a choice of subscription plans after their trial lessons.",
  },
  {
    title: "What are the subscription options?",
    description:
      "You can choose subscriptions between 1 hour/week to 5 hours/week. We recommend that you discuss with your tutor on their availability before selecting your subscription",
  },
  {
    title: "Do lessons expire?",
    description:
      "You can postpone your subscription renewal date and take any remaining lessons, as long as the tutor has availability to book the lessons. However, if you have unscheduled hours on your balance when your subscription renews, 1 week of your monthly hours will be carried forward into the next payment period.",
  },
  {
    title: "What happens if I need to cancel a lesson?",
    description:
      "You will be able to cancel the lesson for free up to 12 hours before the scheduled time. After this time, only full cost cancellation option is available. Plan your schedule with confidence, knowing you have the flexibility you need.",
  },
  {
    title: "What is the meaning of Preply?",
    description: `The name "Preply" is a mash-up, with "PREP-" representing preparation and "-LY" symbolizing speaking confidently. Therefore, the overall meaning is "Prepare to speak confidently.`,
  },
];

const review = [
  {
    name: "Patrick Lebrat",
    date: "Jan 17, 2024",
    des: "I’ve been using Preply for 3 months. I’m really satisfied by the lessons and my teacher. To me, this is the right way to improve my English, getting more fluent and confident. The prices of the lessons are affordable according to the teacher you choose.",
  },
  {
    name: "Carlos Galvez",
    date: "Feb 17, 2024",
    des: "You can find in Preply many native language speaking tutors that can help you to improve your language level, and people of Preply can assist you effectively. awesome!",
  },
  {
    name: "Ashton I",
    date: "Dec 19, 2023",
    des: "Choosing Preply for language learning has been a game-changer for me, and I look forward to continuing my educational journey on this fantastic platform. Kudos to the Preply team and the amazing tutors who enrich the learning experience! Highly recommended! 🌟",
  },
  {
    name: "Adama Illyassa",
    date: "Dec 21, 2023",
    des: "This is a very good site for finding tutors for the languages you need. There are also a lot of great search categories, such as the maximum price of the tutor, the origin of the tutor himself (which country he is from), as well as very quick help in case you have any questions.",
  },
  {
    name: "Irina Komleva",
    date: "Dec 15, 2023",
    des: "Super intuitive platform, great range of teachers, customer support works fast and addresses individual issues. I have been with Preply for more than two years and not planning on change.",
  },
];

const HowWorks = () => {
  const [opensection, setOpenSections] = useState([]);

  const openAccordian = (i) => {
    if (opensection.includes(i)) {
      let _openSection = [...opensection];
      let opensections = _openSection.filter((item) => item != i);
      setOpenSections(opensections);
    } else {
      setOpenSections((prev) => [...prev, i]);
    }
  };

  return (
    <div className="inner-page banr_atch">
      <div class="about_sec how-it-work">
        <div class="container">
          <div class="about_sec_row how-it-work-row">
            <div class="how-it-work-des">
              <h1 class="sub_head">
                How does Preply work: Get fluent with your ideal tutor.
              </h1>
              <p>
                Advance in your career or explore the world with personalized
                1-on-1 language lessons taught by the best online tutors.
              </p>
              <div class="btn_row">
                <Link to={"/online/english-tutors"}>Find My Tutor</Link>
              </div>
            </div>

            <div class="abu_left_right">
              <div class="styles_image__JVF5S">
                <div class="styles-module_container__yaJag">
                  <img
                    sizes="(min-width: 700px) 75w, 100w"
                    src={require("../assets/images/Learner-Magda.webp")}
                    alt="Preply learner, looking at her laptop during an online lesson."
                    loading="eager"
                    width="540"
                    height="384"
                    class="styles_image__JVF5S styles_img__qvNc2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="experience_sec">
        <div class="container">
          <div class="experience_row">
            <div class="expe_block">
              <h3 class="small_head">50,000+</h3>
              <p>Experienced tutors</p>
            </div>

            <div class="expe_block">
              <h3 class="small_head">300,000+</h3>
              <p>5-star tutor reviews</p>
            </div>

            <div class="expe_block">
              <h3 class="small_head">120+</h3>
              <p>Subjects taught</p>
            </div>

            <div class="expe_block">
              <h3 class="small_head">180+</h3>
              <p>Tutor nationalities</p>
            </div>

            <div class="expe_block rating_block">
              <h3 class="small_head">
                4.8
                <div
                  class=" _LayoutGap_1ydxs_442 _LayoutHide_1f3it_164 _LayoutRelative_14cm4_46"
                  data-ds-nowrap="true"
                  data-ds-padding="none"
                  data-ds-direction="row"
                  data-ds-alignitems="center"
                  data-preply-ds-component="LayoutFlex"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                  >
                    <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                  >
                    <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                  >
                    <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                  >
                    <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                  >
                    <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                  </svg>
                </div>
              </h3>
              <p>on the App Store</p>
            </div>
          </div>
        </div>
      </div>
      <div class="Preply_work">
        <div class="container">
          <h1 class="sub_head">How does Preply work?</h1>
          <p>Get fluent in three steps.</p>

          <div class="Preply_work_row">
            <div class="Preply_block">
              <div class="number">
                <span>1</span>
              </div>
              <h3 class="small_head">Find your online tutor</h3>
              <p>
                Choose your learning preferences and get matched with tutors who
                fit your budget and schedule.{" "}
              </p>
              <p>
                You can watch their intro videos, read through their profile and
                reviews, and chat with them to make sure you get the best tutor
                for you.{" "}
              </p>
            </div>

            <div class="Preply_block">
              <div class="number">
                <span>2</span>
              </div>
              <h3 class="small_head">Find your online tutor</h3>
              <p>
                Choose your learning preferences and get matched with tutors who
                fit your budget and schedule.{" "}
              </p>
              <p>
                You can watch their intro videos, read through their profile and
                reviews, and chat with them to make sure you get the best tutor
                for you.{" "}
              </p>
            </div>

            <div class="Preply_block">
              <div class="number">
                <span>3</span>
              </div>
              <h3 class="small_head">Find your online tutor</h3>
              <p>
                Choose your learning preferences and get matched with tutors who
                fit your budget and schedule.{" "}
              </p>
              <p>
                You can watch their intro videos, read through their profile and
                reviews, and chat with them to make sure you get the best tutor
                for you.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="Easily_manage">
        <div class="container Easily_row">
          <div class="Easily_img">
            <img src={require("../assets/images/it_works_06.webp")} />
          </div>
          <div class="Easily_content">
            <h3 class="small_head">Easily manage your subscription</h3>
            <p>
              Schedule weekly lessons at a fixed time or customize them
              individually based on your availability. Easily reschedule, add
              more lessons, or switch tutors. You can also postpone your
              subscription renewal date or cancel it anytime.{" "}
            </p>
          </div>
        </div>
        <div class="btn_row">
          <Link to={"/online/english-tutors"}>Find My Tutor</Link>
        </div>
      </div>
      <div class="Investing_sec benefits">
        <div class="container">
          <h1 class="sub_head">
            What are the benefits of learning on Preply?{" "}
          </h1>
          <div class="Investing_row">
            <div class="styles_imageContainer__zJRy4">
              <div
                class="styles-module_container__yaJag"
                data-preply-ds-component="RebrandStackedImage"
              >
                <img
                  sizes="(min-width: 700px) 75w, 100w"
                  src={require("../assets/images/Preply_learner_Janath.webp")}
                  alt="Preply learner, looking at the camera during an online language lesson."
                  loading="eager"
                  width="527"
                  height="497"
                  class="styles_image__JVF5S styles_img__qvNc2"
                  style={{ borderRadius: "0px" }}
                />
              </div>
            </div>

            <div class="Investing_right">
              <h1 class="midle_head">Learn on your budget and schedule</h1>
              <p>
                Learn with an expert tutor and pay for a subscription that fits
                your budget. Lessons are available 24 hours a day, 365 days a
                year for your convenience.
              </p>
            </div>
          </div>

          <div class="Investing_row benefits_block">
            <div class="Investing_right">
              <h1 class="midle_head">
                Personalized lessons with the right tutor
              </h1>
              <p>
                Set your own goals and get a personalized learning plan. Whether
                you prefer a native-speaking tutor or one who communicates in
                your language, we've got you covered with 32,000+ tutors from
                160+ countries!
              </p>
            </div>

            <div class="styles_imageContainer__zJRy4">
              <div
                class="styles-module_container__yaJag"
                data-preply-ds-component="RebrandStackedImage"
              >
                <img
                  sizes="(min-width: 700px) 75w, 100w"
                  src={require("../assets/images/Preply_tutor_Chris.webp")}
                  alt="Preply learner, looking at the camera during an online language lesson."
                  loading="eager"
                  width="527"
                  height="auto"
                  class="styles_image__JVF5S styles_img__qvNc2"
                  style={{ borderRadius: "0px" }}
                />
              </div>
            </div>
          </div>

          <div class="Investing_row">
            <div class="styles_imageContainer__zJRy4">
              <div
                class="styles-module_container__yaJag"
                data-preply-ds-component="RebrandStackedImage"
              >
                <img
                  sizes="(min-width: 700px) 75w, 100w"
                  src={require("../assets/images/Preply_learner_benefits-3.webp")}
                  alt="Preply learner, looking at the camera during an online language lesson."
                  loading="eager"
                  width="527"
                  height="auto"
                  class="styles_image__JVF5S styles_img__qvNc2"
                  style={{ borderRadius: "0px" }}
                />
              </div>
            </div>

            <div class="Investing_right">
              <h1 class="midle_head">Speak confidently</h1>
              <p>
                Get real-time feedback from real-life online tutors, with
                constant support to accelerate your learning and get you feeling
                confident in no time.
              </p>
            </div>
          </div>

          <div class="Investing_row benefits_block">
            <div class="Investing_right">
              <h1 class="midle_head">Improve beyond your lessons</h1>
              <p>
                Practice between lessons with our vocabulary feature, chat with
                your tutor anytime, and get free access to tons of learning
                tools to help you keep the momentum going.
              </p>
            </div>

            <div class="styles_imageContainer__zJRy4">
              <div
                class="styles-module_container__yaJag"
                data-preply-ds-component="RebrandStackedImage"
              >
                <img
                  sizes="(min-width: 700px) 75w, 100w"
                  src={require("../assets/images/learner_benefits-4.webp")}
                  alt="Preply learner, looking at the camera during an online language lesson."
                  loading="eager"
                  width="527"
                  height="auto"
                  class="styles_image__JVF5S styles_img__qvNc2"
                  style={{ borderRadius: "0px" }}
                />
              </div>
            </div>
          </div>

          <div class="btn_row center_btn">
            <Link to={"/online/english-tutors"}>Find My Tutor</Link>
          </div>
        </div>
      </div>

      <div class="skills ">
        <div class="container">
          <h1 class="sub_head">Experience unparalleled learning.</h1>
          <p>
            Improve your skills using our web and app features crafted by
            language experts.
          </p>

          <div class="Preply_work_row">
            <div class="skills_block">
              <div class="skil-pic">
                <img src={require("../assets/images/motvation.webp")} />
              </div>
              <h3 class="small_head">Stay motivated</h3>
              <p>
                Want to take lessons you truly enjoy? We got you covered with
                Conversation Starters that cover a variety of topics.{" "}
              </p>
            </div>

            <div class="skills_block">
              <div class="skil-pic">
                <img src={require("../assets/images/track.webp")} />
              </div>
              <h3 class="small_head">Track your progress</h3>
              <p>
                Improve your skills in every lesson with our speaking tracker
                and progress tests.
              </p>
            </div>

            <div class="skills_block">
              <div class="skil-pic">
                <img src={require("../assets/images/improve.webp")} />
              </div>
              <h3 class="small_head">Improve your vocab</h3>
              <p>
                Reinforce your learnings with daily practice using our Vocab
                feature.{" "}
              </p>
            </div>

            <div class="skills_block">
              <div class="skil-pic">
                <img src={require("../assets/images/speak.webp")} />
              </div>
              <h3 class="small_head">Speak, speak, speak!</h3>
              <p>
                Practice at your own pace and correct mistakes using our new AI
                speaking feature!{" "}
              </p>
            </div>
          </div>

          <div class="slid-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              data-preply-ds-component="SvgIcon"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
            >
              <path
                fill-rule="evenodd"
                d="M9.293 3.293a1 1 0 0 0 0 1.414L16.586 12l-7.293 7.293a1 1 0 1 0 1.414 1.414l8-8a1 1 0 0 0 0-1.414l-8-8a1 1 0 0 0-1.414 0"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div class="our_users">
        <div class="container">
          <h1 class="sub_head">What our users say.</h1>
          <div class="our_users_grid">
            {review.map((item) => {
              return (
                <div class="our_users_block">
                  <h3>
                    {item.name}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="15"
                        fill="none"
                        viewBox="0 0 16 15"
                      >
                        <path
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-linejoin="round"
                          stroke-width="1.875"
                          d="M6 5.5 8 1l2 4.5 5 .5-3.706 3.235L12.5 14 8 11.706 3.5 14l1.206-4.765L1 6z"
                        ></path>
                      </svg>
                      5
                    </span>
                  </h3>
                  <span class="date_time">{item.date}</span>
                  <p>{item.des}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div class="our_users opportunities">
        <div class="container">
          <h1 class="sub_head">Find the right tutor for you.</h1>
          <p>
            Start your learning journey and unleash new opportunities with a
            Preply tutor.
          </p>

          <div class="opportunities_row">
            <div class="opportunities_colm">
              <div class="oppor_sm_grid">
                <div class="oppor_sub_grid">
                  <div class="oppor_left_grid">
                    <img src={require("../assets/images/avatar.jpg")} />
                  </div>
                  <div class="oppor_right_grid">
                    <div class="oppor_name_flag">
                      <a href="#" class="styles_link__k_pxX">
                        <p>Amanda B.</p>
                      </a>
                      <span
                        class="_Icon_1lkov_4 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.291 4.055 12 2 8.709 4.055l-3.78.874-.874 3.78L2 12l2.055 3.291.874 3.78 3.78.874L12 22l3.291-2.055 3.78-.874.874-3.78L22 12l-2.055-3.291-.874-3.78zM9.793 15.707l.707.707.707-.707 6-6-1.414-1.414-5.293 5.293-2.293-2.293-1.414 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <img
                        src={require("../assets/images/united-kingdom.png")}
                        alt="United Kingdom"
                        width="16px"
                        class="styles-module_root__FKhpE styles-module_sizeXS__BVGqr"
                        data-preply-ds-component="RebrandFlag"
                      />
                    </div>
                    <div class="Speaks">
                      <span
                        class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3H8v2H3v2h8.015a8.5 8.5 0 0 1-1.314 4.286L6.707 8.293 5.293 9.707l3.166 3.166c-.297.312-.606.6-.918.865a14.3 14.3 0 0 1-3.565 2.196 8 8 0 0 1-.297.119l-.012.004L4 17l.334.943.004-.002.007-.002.025-.01a8 8 0 0 0 .383-.152 16.3 16.3 0 0 0 4.081-2.514q.527-.445 1.039-.976l1.42 1.42 1.414-1.414-1.563-1.563A10.5 10.5 0 0 0 13.017 7H15V5h-5zm8.253 17-.597-1.596H14.34L13.74 20H11.7l3.264-8.4h2.064l3.265 8.4zm-2.255-6.027 1.02 2.727h-2.041z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <p>Speaks English (Native)</p>
                    </div>
                    <div class="price_rating">
                      <div class="price_rate">
                        <h4>₹3,056</h4>
                        <p>per lesson</p>
                      </div>

                      <div class="right_rate">
                        <h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            data-preply-ds-component="SvgTokyoUIIcon"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                          </svg>
                          4.98
                        </h4>
                        <p>41 reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="last_content">
                  <p>
                    Qualified English teacher from Oxford, England. I specialize
                    in teaching English as a Foreign Language and in examination
                    preparation, inc...
                  </p>
                  <a href="#">Read More</a>
                  <div class="btn_row left_btn">
                    <a href="#">Book Trial Lesson</a>
                  </div>
                </div>
              </div>

              <div class="oppor_sm_grid">
                <div class="oppor_sub_grid">
                  <div class="oppor_left_grid">
                    <img src={require("../assets/images/avatar-2.jpg")} />
                  </div>
                  <div class="oppor_right_grid">
                    <div class="oppor_name_flag">
                      <a href="#" class="styles_link__k_pxX">
                        <p>Emmy W.</p>
                      </a>
                      <span
                        class="_Icon_1lkov_4 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.291 4.055 12 2 8.709 4.055l-3.78.874-.874 3.78L2 12l2.055 3.291.874 3.78 3.78.874L12 22l3.291-2.055 3.78-.874.874-3.78L22 12l-2.055-3.291-.874-3.78zM9.793 15.707l.707.707.707-.707 6-6-1.414-1.414-5.293 5.293-2.293-2.293-1.414 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <img
                        src={require("../assets/images/united-kingdom.png")}
                        alt="United Kingdom"
                        width="16px"
                        class="styles-module_root__FKhpE styles-module_sizeXS__BVGqr"
                        data-preply-ds-component="RebrandFlag"
                      />
                    </div>
                    <div class="Speaks">
                      <span
                        class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3H8v2H3v2h8.015a8.5 8.5 0 0 1-1.314 4.286L6.707 8.293 5.293 9.707l3.166 3.166c-.297.312-.606.6-.918.865a14.3 14.3 0 0 1-3.565 2.196 8 8 0 0 1-.297.119l-.012.004L4 17l.334.943.004-.002.007-.002.025-.01a8 8 0 0 0 .383-.152 16.3 16.3 0 0 0 4.081-2.514q.527-.445 1.039-.976l1.42 1.42 1.414-1.414-1.563-1.563A10.5 10.5 0 0 0 13.017 7H15V5h-5zm8.253 17-.597-1.596H14.34L13.74 20H11.7l3.264-8.4h2.064l3.265 8.4zm-2.255-6.027 1.02 2.727h-2.041z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <p>Speaks English (Native)</p>
                    </div>
                    <div class="price_rating">
                      <div class="price_rate">
                        <h4>₹1,698</h4>
                        <p>per lesson</p>
                      </div>

                      <div class="right_rate">
                        <h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            data-preply-ds-component="SvgTokyoUIIcon"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                          </svg>
                          5
                        </h4>
                        <p>9 reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="last_content">
                  <p>
                    Certified Native English tutor passionate about leading
                    creative, engaging, student-led lessons! – Hello, my name is
                    Emmy from Yorkshire, which...
                  </p>
                  <a href="#">Read More</a>
                  <div class="btn_row left_btn">
                    <a href="#">Book Trial Lesson</a>
                  </div>
                </div>
              </div>

              <div class="oppor_sm_grid">
                <div class="oppor_sub_grid">
                  <div class="oppor_left_grid">
                    <img src={require("../assets/images/avatar-3.jpg")} />
                  </div>
                  <div class="oppor_right_grid">
                    <div class="oppor_name_flag">
                      <a href="#" class="styles_link__k_pxX">
                        <p>Sophia K.</p>
                      </a>
                      <span
                        class="_Icon_1lkov_4 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.291 4.055 12 2 8.709 4.055l-3.78.874-.874 3.78L2 12l2.055 3.291.874 3.78 3.78.874L12 22l3.291-2.055 3.78-.874.874-3.78L22 12l-2.055-3.291-.874-3.78zM9.793 15.707l.707.707.707-.707 6-6-1.414-1.414-5.293 5.293-2.293-2.293-1.414 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>

                      <img
                        src={require("../assets/images/united-kingdom.png")}
                        alt="United Kingdom"
                        width="16px"
                        class="styles-module_root__FKhpE styles-module_sizeXS__BVGqr"
                        data-preply-ds-component="RebrandFlag"
                      />
                    </div>
                    <div class="Speaks">
                      <span
                        class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3H8v2H3v2h8.015a8.5 8.5 0 0 1-1.314 4.286L6.707 8.293 5.293 9.707l3.166 3.166c-.297.312-.606.6-.918.865a14.3 14.3 0 0 1-3.565 2.196 8 8 0 0 1-.297.119l-.012.004L4 17l.334.943.004-.002.007-.002.025-.01a8 8 0 0 0 .383-.152 16.3 16.3 0 0 0 4.081-2.514q.527-.445 1.039-.976l1.42 1.42 1.414-1.414-1.563-1.563A10.5 10.5 0 0 0 13.017 7H15V5h-5zm8.253 17-.597-1.596H14.34L13.74 20H11.7l3.264-8.4h2.064l3.265 8.4zm-2.255-6.027 1.02 2.727h-2.041z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <p>Speaks English (Native)</p>
                    </div>
                    <div class="price_rating">
                      <div class="price_rate">
                        <h4>₹1,867</h4>
                        <p>per lesson</p>
                      </div>

                      <div class="right_rate">
                        <h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            data-preply-ds-component="SvgTokyoUIIcon"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                          </svg>
                          5
                        </h4>
                        <p>10 reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="last_content">
                  <p>
                    Bilingual English-Spanish tutor with over 4 years of
                    experience! – Hello! My name is Sophia, and I'm originally
                    from California, a state on the west...
                  </p>
                  <a href="#">Read More</a>
                  <div class="btn_row left_btn">
                    <a href="#">Book Trial Lesson</a>
                  </div>
                </div>
              </div>

              <div class="oppor_sm_grid">
                <div class="oppor_sub_grid">
                  <div class="oppor_left_grid">
                    <img src={require("../assets/images/avatar-4.jpg")} />
                  </div>
                  <div class="oppor_right_grid">
                    <div class="oppor_name_flag">
                      <a href="#" class="styles_link__k_pxX">
                        <p>Damaris O.</p>
                      </a>
                      <span
                        class="_Icon_1lkov_4 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.291 4.055 12 2 8.709 4.055l-3.78.874-.874 3.78L2 12l2.055 3.291.874 3.78 3.78.874L12 22l3.291-2.055 3.78-.874.874-3.78L22 12l-2.055-3.291-.874-3.78zM9.793 15.707l.707.707.707-.707 6-6-1.414-1.414-5.293 5.293-2.293-2.293-1.414 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>

                      <img
                        src={require("../assets/images/united-kingdom.png")}
                        alt="United Kingdom"
                        width="16px"
                        class="styles-module_root__FKhpE styles-module_sizeXS__BVGqr"
                        data-preply-ds-component="RebrandFlag"
                      />
                    </div>
                    <div class="Speaks">
                      <span
                        class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3H8v2H3v2h8.015a8.5 8.5 0 0 1-1.314 4.286L6.707 8.293 5.293 9.707l3.166 3.166c-.297.312-.606.6-.918.865a14.3 14.3 0 0 1-3.565 2.196 8 8 0 0 1-.297.119l-.012.004L4 17l.334.943.004-.002.007-.002.025-.01a8 8 0 0 0 .383-.152 16.3 16.3 0 0 0 4.081-2.514q.527-.445 1.039-.976l1.42 1.42 1.414-1.414-1.563-1.563A10.5 10.5 0 0 0 13.017 7H15V5h-5zm8.253 17-.597-1.596H14.34L13.74 20H11.7l3.264-8.4h2.064l3.265 8.4zm-2.255-6.027 1.02 2.727h-2.041z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <p>Speaks English (Native)</p>
                    </div>
                    <div class="price_rating">
                      <div class="price_rate">
                        <h4>₹2,122</h4>
                        <p>per lesson</p>
                      </div>

                      <div class="right_rate">
                        <h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            data-preply-ds-component="SvgTokyoUIIcon"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                          </svg>
                          5
                        </h4>
                        <p>4 reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="last_content">
                  <p>
                    Achieve your language goals with an engaging English tutor!
                    – Hello! My name is Damaris and I come from rural South West
                    England. I currently...
                  </p>
                  <a href="#">Read More</a>
                  <div class="btn_row left_btn">
                    <a href="#">Book Trial Lesson</a>
                  </div>
                </div>
              </div>

              <div class="oppor_sm_grid">
                <div class="oppor_sub_grid">
                  <div class="oppor_left_grid">
                    <img src={require("../assets/images/avatar-5.jpg")} />
                  </div>
                  <div class="oppor_right_grid">
                    <div class="oppor_name_flag">
                      <a href="#" class="styles_link__k_pxX">
                        <p>Jordan H.</p>
                      </a>
                      <span
                        class="_Icon_1lkov_4 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.291 4.055 12 2 8.709 4.055l-3.78.874-.874 3.78L2 12l2.055 3.291.874 3.78 3.78.874L12 22l3.291-2.055 3.78-.874.874-3.78L22 12l-2.055-3.291-.874-3.78zM9.793 15.707l.707.707.707-.707 6-6-1.414-1.414-5.293 5.293-2.293-2.293-1.414 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>

                      <img
                        src={require("../assets/images/united-kingdom.png")}
                        alt="United Kingdom"
                        width="16px"
                        class="styles-module_root__FKhpE styles-module_sizeXS__BVGqr"
                        data-preply-ds-component="RebrandFlag"
                      />
                    </div>
                    <div class="Speaks">
                      <span
                        class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3H8v2H3v2h8.015a8.5 8.5 0 0 1-1.314 4.286L6.707 8.293 5.293 9.707l3.166 3.166c-.297.312-.606.6-.918.865a14.3 14.3 0 0 1-3.565 2.196 8 8 0 0 1-.297.119l-.012.004L4 17l.334.943.004-.002.007-.002.025-.01a8 8 0 0 0 .383-.152 16.3 16.3 0 0 0 4.081-2.514q.527-.445 1.039-.976l1.42 1.42 1.414-1.414-1.563-1.563A10.5 10.5 0 0 0 13.017 7H15V5h-5zm8.253 17-.597-1.596H14.34L13.74 20H11.7l3.264-8.4h2.064l3.265 8.4zm-2.255-6.027 1.02 2.727h-2.041z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <p>Speaks English (Native)</p>
                    </div>
                    <div class="price_rating">
                      <div class="price_rate">
                        <h4>₹1,698</h4>
                        <p>per lesson</p>
                      </div>

                      <div class="right_rate">
                        <h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            data-preply-ds-component="SvgTokyoUIIcon"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                          </svg>
                          5
                        </h4>
                        <p>5 reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="last_content">
                  <p>
                    Experienced English Language Tutor with 5 Years of
                    Experience – Hello! My name is Jordan and I am very
                    passionate about teaching...
                  </p>
                  <a href="#">Read More</a>
                  <div class="btn_row left_btn">
                    <a href="#">Book Trial Lesson</a>
                  </div>
                </div>
              </div>

              <div class="oppor_sm_grid">
                <div class="oppor_sub_grid">
                  <div class="oppor_left_grid">
                    <img src={require("../assets/images/avatar-6.jpg")} />
                  </div>
                  <div class="oppor_right_grid">
                    <div class="oppor_name_flag">
                      <a href="#" class="styles_link__k_pxX">
                        <p>Ryan C.</p>
                      </a>
                      <span
                        class="_Icon_1lkov_4 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15.291 4.055 12 2 8.709 4.055l-3.78.874-.874 3.78L2 12l2.055 3.291.874 3.78 3.78.874L12 22l3.291-2.055 3.78-.874.874-3.78L22 12l-2.055-3.291-.874-3.78zM9.793 15.707l.707.707.707-.707 6-6-1.414-1.414-5.293 5.293-2.293-2.293-1.414 1.414z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>

                      <img
                        src={require("../assets/images/united-kingdom.png")}
                        alt="United Kingdom"
                        width="16px"
                        class="styles-module_root__FKhpE styles-module_sizeXS__BVGqr"
                        data-preply-ds-component="RebrandFlag"
                      />
                    </div>
                    <div class="Speaks">
                      <span
                        class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                        data-preply-ds-component="Icon"
                        role="img"
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 3H8v2H3v2h8.015a8.5 8.5 0 0 1-1.314 4.286L6.707 8.293 5.293 9.707l3.166 3.166c-.297.312-.606.6-.918.865a14.3 14.3 0 0 1-3.565 2.196 8 8 0 0 1-.297.119l-.012.004L4 17l.334.943.004-.002.007-.002.025-.01a8 8 0 0 0 .383-.152 16.3 16.3 0 0 0 4.081-2.514q.527-.445 1.039-.976l1.42 1.42 1.414-1.414-1.563-1.563A10.5 10.5 0 0 0 13.017 7H15V5h-5zm8.253 17-.597-1.596H14.34L13.74 20H11.7l3.264-8.4h2.064l3.265 8.4zm-2.255-6.027 1.02 2.727h-2.041z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                      <p>Speaks English (Native)</p>
                    </div>
                    <div class="price_rating">
                      <div class="price_rate">
                        <h4>₹1,736</h4>
                        <p>per lesson</p>
                      </div>

                      <div class="right_rate">
                        <h4>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            data-preply-ds-component="SvgTokyoUIIcon"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            focusable="false"
                          >
                            <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                          </svg>
                          5
                        </h4>
                        <p>100 reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="last_content">
                  <p>
                    Professional IELTS and General English teacher – Hi. I'm
                    Ryan. I've been teaching IELTS and General English for 5
                    years. I am TESOL certified,...
                  </p>
                  <a href="#">Read More</a>
                  <div class="btn_row left_btn">
                    <a href="#">Book Trial Lesson</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_row center_btn">
              <Link to={"/online/english-tutors"}>Explore More Tutors</Link>
            </div>
          </div>
        </div>
      </div>
      <div class="faqs_sec">
        <div class="Frequently container">
          <h1 class="sub_head">FAQs on how Preply works.</h1>
          <div className="faq-accordion" style={{ marginTop: "50px" }}>
            {accordianData.map((item, i) => {
              return (
                <div
                  style={{
                    boxShadow: "0px 0px 8px rgba(9, 15, 25, .1)",
                    padding: "24px 16px",
                    borderRadius: "8px",
                    marginBottom: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: 1 }}>
                    <div
                      onClick={() => openAccordian(i)}
                      style={{
                        fontWeight: 600,
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    >
                      {item.title}
                    </div>
                    <div
                      class=""
                      style={{
                        display: opensection.includes(i) ? "block" : "none",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      <div class="">{item.description}</div>
                    </div>
                  </div>
                  <div
                    style={{ width: "20px", cursor: "pointer" }}
                    onClick={() => openAccordian(i)}
                  >
                    {opensection.includes(i) && <Arrowdown />}
                    {!opensection.includes(i) && <Arrowup />}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div class="Still_got">
        <div class="container">
          {/* <div class="Still_got_row">
            <h3 class="small_head">Easily manage your subscription</h3>
            <p>
              If you have any more questions or need advice, check out our{" "}
              <a href="#">Help Center</a> your one-stop shop for tips and
              answers from the Preply Team.
            </p>
          </div> */}
          <div class="Still_got_row">
            <h3 class="small_head">Still got doubts?</h3>
            <p>
              If you have any more questions or need advice, check out our{" "}
              <a href="#">Help Center</a> your one-stop shop for tips and
              answers from the Preply Team.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowWorks;
