import React from "react";
import { Link } from "react-router";

const Homepage = () => {
  return (
    <div className="homePage">
      {/* Banner Section start */}
      <section class="banner_section">
        <div class="container">
          <div class="banner_left_wrapper">
            <div class="banner_content">
              <div class="gateway_part">
                <div class="gateway">
                  <div class="gateway_wrap">
                    <img
                      src={
                        require("../assets/images/school_degree_icon.svg")
                          .default
                      }
                      alt="degree_icon"
                    />
                    <span>Gateway to Lifelong Learning</span>
                  </div>
                </div>
              </div>
              <div class="banner_heading">
                <h1>
                  Welcome to Your <br />
                  Exciting <span>Learning</span>
                  <br />
                  Journey
                </h1>
                <p>
                  We Have 30k+ Online Courses & 300k+ Online <br />
                  Registered Student.
                </p>
              </div>
              <div class="banner_button">
                <div class="banner_btn">
                  <a href="#">View Courses</a>
                </div>
                <div class="view_students">
                  <img
                    src={require("../assets/images/view_student.svg").default}
                    alt="view-students"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="banner_right_wrapper">
            <div class="banner_image">
              <img
                src={require("../assets/images/banner_image.svg").default}
                alt="banner_image"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Streaming section start */}

      <section class="streaming_section">
        <div class="container">
          <div class="streaming_wrapper">
            <div class="streaming_class">
              <div class="streaming_img">
                <img
                  src={require("../assets/images/online_class_img.svg").default}
                  alt="online_class"
                />
              </div>
              <div class="streaming_heading">
                <h2>
                  Join Our Live <br />
                  Streaming Classes!
                </h2>
                <p>
                  Don’t miss out on our live event! Tune in to watch exciting
                  content, interact in real time, and be part of the action.
                  Click the link below to join the stream!
                </p>
                <div class="streaming_btn">
                  <a href="#">Join Live Now</a>
                </div>
              </div>
            </div>
            <div class="streaming_class">
              <div class="streaming_img">
                <img
                  src={require("../assets/images/online_video_img.svg").default}
                  alt="online_class"
                />
              </div>
              <div class="streaming_heading">
                <h2>
                  Join Our <br />
                  Online Video!
                </h2>
                <p>
                  Catch up on all the content you missed with our recorded
                  videos! Watch at your convenience & learn at your own pace.
                  Click the link below to explore our library of recordings!
                </p>
                <div class="streaming_btn">
                  <a href="#">Join Live Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* tutor start*/}

      <section class="find_tutor_section">
        <div class="container">
          <div class="fnd_tutor_wrapper">
            <h3>
              <img
                src={require("../assets/images/find_tutor_icon.svg").default}
                alt="find_tutor_icon"
              />
              <span>Find Tutor</span>
            </h3>
            <h2>Find the Right Tutor for you</h2>
          </div>
        </div>
        <div class="container">
          <div class="tutor_wrapper">
            <div class="tutor_card_wraper">
              <div class="tutor_card">
                <div class="tutor_city">
                  <img
                    src={require("../assets/images/english_tutor.svg").default}
                    alt="english_tutor"
                  />
                </div>
                <div class="tutor_language">
                  <h4>English tutors</h4>
                  <p>28,477 teachers</p>
                </div>
              </div>
              <div class="tutor_side_icon">
                <img
                  src={require("../assets/images/tutor_side_icon.svg").default}
                  alt="tutor"
                />
              </div>
            </div>
            <div class="tutor_card_wraper">
              <div class="tutor_card">
                <div class="tutor_city">
                  <img
                    src={require("../assets/images/spanish_icon.svg").default}
                    alt="spanish_icon"
                  />
                </div>
                <div class="tutor_language">
                  <h4>Spanish tutors</h4>
                  <p>2.342 teachers</p>
                </div>
              </div>
              <div class="tutor_side_icon">
                <img
                  src={require("../assets/images/tutor_side_icon.svg").default}
                  alt="tutor"
                />
              </div>
            </div>
            <div class="tutor_card_wraper">
              <div class="tutor_card">
                <div class="tutor_city">
                  <img
                    src={require("../assets/images/french_icon.svg").default}
                    alt="french_icon"
                  />
                </div>
                <div class="tutor_language">
                  <h4>French tutors</h4>
                  <p>9,042 teachers</p>
                </div>
              </div>
              <div class="tutor_side_icon">
                <img
                  src={require("../assets/images/tutor_side_icon.svg").default}
                  alt="tutor"
                />
              </div>
            </div>
            <div class="tutor_card_wraper">
              <div class="tutor_card">
                <div class="tutor_city">
                  <img
                    src={require("../assets/images/german_icon.svg").default}
                    alt="german_icon"
                  />
                </div>
                <div class="tutor_language">
                  <h4>German tutors</h4>
                  <p>3,386 teachers</p>
                </div>
              </div>
              <div class="tutor_side_icon">
                <img
                  src={require("../assets/images/tutor_side_icon.svg").default}
                  alt="tutor"
                />
              </div>
            </div>
            <div class="tutor_card_wraper">
              <div class="tutor_card">
                <div class="tutor_city">
                  <img
                    src={require("../assets/images/italian_icon.svg").default}
                    alt="italian_icon"
                  />
                </div>
                <div class="tutor_language">
                  <h4>Italian tutors</h4>
                  <p>1417 teachers</p>
                </div>
              </div>
              <div class="tutor_side_icon">
                <img
                  src={require("../assets/images/tutor_side_icon.svg").default}
                  alt="tutor"
                />
              </div>
            </div>
            <div class="tutor_card_wraper">
              <div class="tutor_card">
                <div class="tutor_city">
                  <img
                    src={require("../assets/images/chinese_icon.svg").default}
                    alt="chinese_icon"
                  />
                </div>
                <div class="tutor_language">
                  <h4>Chinese tutors</h4>
                  <p>4,953 teachers</p>
                </div>
              </div>
              <div class="tutor_side_icon">
                <img
                  src={require("../assets/images/tutor_side_icon.svg").default}
                  alt="tutor"
                />
              </div>
            </div>
            <div class="tutor_card_wraper">
              <div class="tutor_card">
                <div class="tutor_city">
                  <img
                    src={require("../assets/images/arabic_icon.svg").default}
                    alt="arabic_icon"
                  />
                </div>
                <div class="tutor_language">
                  <h4>Arabic tutors</h4>
                  <p>3,315 teachers</p>
                </div>
              </div>
              <div class="tutor_side_icon">
                <img
                  src={require("../assets/images/tutor_side_icon.svg").default}
                  alt="tutor"
                />
              </div>
            </div>
            <div class="tutor_card_wraper">
              <div class="tutor_card">
                <div class="tutor_city">
                  <img
                    src={require("../assets/images/japanese_icon.svg").default}
                    alt="japanese_icon"
                  />
                </div>
                <div class="tutor_language">
                  <h4>Japanese tutors</h4>
                  <p>2,383 teachers</p>
                </div>
              </div>
              <div class="tutor_side_icon">
                <img
                  src={require("../assets/images/tutor_side_icon.svg").default}
                  alt="tutor"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* cources Start */}

      <section class="courses_section">
        <div class="container">
          <div class="courses_heading_wrapper">
            <h3>
              <img
                src={require("../assets/images/find_tutor_icon.svg").default}
                alt="find_tutor_icon"
              />
              <span>Courses</span>
            </h3>
            <h2>Explore Courses</h2>
          </div>
        </div>
        <div class="container">
          <div class="courses_wrapper">
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/data_scientist.svg").default}
                  alt="data_scientist"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Data Science</h4>
                <p>425 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/business.svg").default}
                  alt="business"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Business</h4>
                <p>1095 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/computer_science.svg").default}
                  alt="computer_science"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Computer Science</h4>
                <p>668 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/health.svg").default}
                  alt="health"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Health</h4>
                <p>471 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/social_science.svg").default}
                  alt="social_science"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Social Science</h4>
                <p>425 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={
                    require("../assets/images/personal_development.svg").default
                  }
                  alt="personal_development"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Persnal Development</h4>
                <p>1095 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/art&humanties.svg").default}
                  alt="art_humanities"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Arts & Humanities.</h4>
                <p>668 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/pshycial_science.svg").default}
                  alt="Physical Science &
              Engineering"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Physical Science & Engineering</h4>
                <p>471 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={
                    require("../assets/images/language_learning.svg").default
                  }
                  alt="language_learning"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Language Learning</h4>
                <p>425 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={
                    require("../assets/images/information_technology.svg")
                      .default
                  }
                  alt="information_technology"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Information Technology</h4>
                <p>1095 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/math_logic.svg").default}
                  alt="math_logic"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Math and Logic</h4>
                <p>668 courses</p>
              </div>
            </div>
            <div class="courses_card">
              <div class="courses_img">
                <img
                  src={require("../assets/images/math_logic.svg").default}
                  alt="math_logic"
                />
              </div>
              <div class="courses_descriptin">
                <h4>Math and Logic</h4>
                <p>668 courses</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Pre apply start*/}
      <section class="preply_Section">
        <div class="container">
          <div class="courses_heading_wrapper">
            <h3>
              <img
                src={require("../assets/images/find_tutor_icon.svg").default}
                alt="find_tutor_icon"
              />
              <span>How we work</span>
            </h3>
            <h2>How Preply Works</h2>
          </div>
        </div>
        <div class="container">
          <div class="preply_card_section">
            <div class="preply_card_wrapper">
              <div class="preply_number">
                <img
                  src={require("../assets/images/01.svg").default}
                  alt="01"
                />
              </div>
              <div class="preply_card_heading">
                <h3>Find your tutor</h3>
                <p>
                  We'll connect you with a tutor who will motivate, challenge,
                  and inspire you.
                </p>
              </div>
              <div class="preply_card">
                <div class="cards_wrap">
                  <img
                    src={require("../assets/images/milena.svg").default}
                    alt="milena"
                  />
                </div>
                <div class="cards_wrap">
                  <img
                    src={require("../assets/images/milena_2.svg").default}
                    alt="milena"
                  />
                </div>
                <div class="cards_wrap">
                  <img
                    src={require("../assets/images/milena_3.svg").default}
                    alt="milena"
                  />
                </div>
              </div>
              <div class="preply_learning_c">
                <img
                  src={require("../assets/images/learning_c.svg").default}
                  alt="learning"
                />
              </div>
            </div>
            <div class="preply_card_two_wrapepr">
              <div class="preply_number">
                <img
                  src={require("../assets/images/02.svg").default}
                  alt="02"
                />
              </div>
              <div class="preply_card_heading">
                <h3>Start learning</h3>
                <p>
                  Your tutor will guide the way through your first lesson and
                  help you plan your next steps.
                </p>
              </div>
              <div class="preply_card_learning">
                <div class="learning_card_image_big">
                  <img
                    src={
                      require("../assets/images/learning_crad_big_image.svg")
                        .default
                    }
                    alt="learning_card_image_big"
                  />
                  <div class="learning_small_image">
                    <img
                      src={
                        require("../assets/images/learning_card_small_img.svg")
                          .default
                      }
                      alt="learning_card_small_img"
                    />
                  </div>
                </div>
                <div class="learning_card_img_im">
                  <img
                    src={require("../assets/images/learning_2.svg").default}
                    alt="learning_2"
                  />
                </div>
              </div>
            </div>
            <div class="preply_card_three_wrapepr">
              <div class="preply_number">
                <img
                  src={require("../assets/images/03.svg").default}
                  alt="03"
                />
              </div>
              <div class="preply_card_heading">
                <h3>Speak. Read. Write. Repeat.</h3>
                <p>
                  Choose how many lessons you want to take each week and get
                  ready to reach your goals!
                </p>
              </div>
              <div class="read_right_wrapper">
                <div class="read_right_one">
                  <img
                    src={
                      require("../assets/images/read_write_image.svg").default
                    }
                    alt="read_right_one"
                  />
                </div>
                <div class="read_right_one">
                  <img
                    src={
                      require("../assets/images/read_write_image.svg").default
                    }
                    alt="read_right_one"
                  />
                </div>
                <div class="read_right_one">
                  <img
                    src={
                      require("../assets/images/read_write_image.svg").default
                    }
                    alt="read_right_one"
                  />
                </div>
              </div>
              <div class="read_right_wrap">
                <img
                  src={require("../assets/images/learning_3.svg").default}
                  alt="learning_3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Lessons you'll love, Guaranteed. started */}

      <section class="newsletter_section">
        <div class="container">
          <div class="newsletter_wrapper">
            <h2>Lessons you'll love, Guaranteed.</h2>
            <p>Try another tutor for free if you're not satisfied.</p>
          </div>
        </div>
      </section>

      {/** tutor section start* */}

      <section class="tutor_section">
        <div class="container">
          <div class="tutor_wrapper">
            <div class="tutor_left_wrapper">
              <h2>Become a Tutor</h2>
              <p>
                Earn money sharing your expert knowledge with students. Sign up
                to start tutoring online with Preply.
              </p>
              <div class="tutor_lists">
                <ul>
                  <li>
                    <img
                      src={require("../assets/images/tutor_icon.svg").default}
                      alt="tutor_icon"
                    />
                    <span>Find new students</span>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/tutor_icon.svg").default}
                      alt="tutor_icon"
                    />
                    <span>Grow your business</span>
                  </li>
                  <li>
                    <img
                      src={require("../assets/images/tutor_icon.svg").default}
                      alt="tutor_icon"
                    />
                    <span>Get paid securely</span>
                  </li>
                </ul>
              </div>
              <div class="tutor_btn">
                <Link to="/teach">Become a Tutor</Link>
              </div>
              <div class="plateform_link">
                <Link to={"/how-it-works"}>How our platform works</Link>
              </div>
            </div>
            <div class="tutor_right_wrapper">
              <img
                src={require("../assets/images/tutor_image.svg").default}
                alt="tutor_image"
              />
            </div>
          </div>
        </div>
      </section>

      {/** tutor section end* */}
    </div>
  );
};

export default Homepage;
