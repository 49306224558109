export const GoogleLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      aria-hidden="true"
      focusable="false"
    >
      <path
        fill="#FFC107"
        d="M43.611 20.083H42V20H24v8h11.303c-1.649 4.657-6.08 8-11.303 8-6.627 0-12-5.373-12-12s5.373-12 12-12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 12.955 4 4 12.955 4 24s8.955 20 20 20 20-8.955 20-20c0-1.341-.138-2.65-.389-3.917"
      ></path>
      <path
        fill="#FF3D00"
        d="m6.306 14.691 6.571 4.819C14.655 15.108 18.961 12 24 12c3.059 0 5.842 1.154 7.961 3.039l5.657-5.657C34.046 6.053 29.268 4 24 4 16.318 4 9.656 8.337 6.306 14.691"
      ></path>
      <path
        fill="#4CAF50"
        d="M24 44c5.166 0 9.86-1.977 13.409-5.192l-6.19-5.238A11.9 11.9 0 0 1 24 36c-5.202 0-9.619-3.317-11.283-7.946l-6.522 5.025C9.505 39.556 16.227 44 24 44"
      ></path>
      <path
        fill="#1976D2"
        d="M43.611 20.083H42V20H24v8h11.303a12.04 12.04 0 0 1-4.087 5.571l.003-.002 6.19 5.238C36.971 39.205 44 34 44 24c0-1.341-.138-2.65-.389-3.917"
      ></path>
    </svg>
  );
};

export const FacebookLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      aria-hidden="true"
      focusable="false"
    >
      <linearGradient
        id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1"
        x1="9.993"
        x2="40.615"
        y1="9.993"
        y2="40.615"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#2aa4f4"></stop>
        <stop offset="1" stop-color="#007ad9"></stop>
      </linearGradient>
      <path
        fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)"
        d="M24 4C12.954 4 4 12.954 4 24s8.954 20 20 20 20-8.954 20-20S35.046 4 24 4"
      ></path>
      <path
        fill="#fff"
        d="M26.707 29.301h5.176l.813-5.258h-5.989v-2.874c0-2.184.714-4.121 2.757-4.121h3.283V12.46c-.577-.078-1.797-.248-4.102-.248-4.814 0-7.636 2.542-7.636 8.334v3.498H16.06v5.258h4.948v14.452c.98.146 1.973.246 2.992.246.921 0 1.82-.084 2.707-.204z"
      ></path>
    </svg>
  );
};

export const AppleLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      aria-hidden="true"
      focusable="false"
    >
      <path d="M44.527 34.75c-1.078 2.395-1.597 3.465-2.984 5.578-1.941 2.953-4.68 6.64-8.063 6.664-3.011.028-3.789-1.965-7.878-1.93-4.086.02-4.938 1.97-7.954 1.938-3.386-.031-5.976-3.352-7.918-6.3-5.43-8.27-6.003-17.966-2.648-23.122 2.375-3.656 6.129-5.805 9.656-5.805 3.594 0 5.852 1.973 8.82 1.973 2.883 0 4.637-1.976 8.794-1.976 3.14 0 6.46 1.71 8.836 4.664-7.766 4.257-6.504 15.347 1.34 18.316M31.195 8.469c1.512-1.942 2.66-4.68 2.242-7.469-2.464.168-5.347 1.742-7.03 3.781-1.528 1.86-2.794 4.617-2.302 7.285 2.692.086 5.477-1.52 7.09-3.597"></path>
    </svg>
  );
};
export const EyeClose = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
      focusable="false"
    >
      <path
        fill-rule="evenodd"
        d="M3 5.414 4.414 4l14.85 14.85-1.415 1.413-1.936-1.935a9.695 9.695 0 0 1-3.913.806c-7 0-10-7-10-7s1.036-2.416 3.31-4.41L3 5.414Zm9.538 9.538 1.824 1.824a7.789 7.789 0 0 1-2.362.358c-2.764 0-4.77-1.364-6.16-2.86a12.39 12.39 0 0 1-1.543-2.069l-.042-.07.042-.072c.337-.566.85-1.32 1.544-2.068.271-.293.567-.58.886-.853l2.321 2.32a3 3 0 0 0 3.49 3.49Zm5.793-.868a12.455 12.455 0 0 0 1.372-1.879l.042-.07-.042-.072a12.39 12.39 0 0 0-1.544-2.068C16.77 8.5 14.764 7.135 12 7.135c-.203 0-.402.007-.597.02L9.658 5.412A9.869 9.869 0 0 1 12 5.134c7 0 10 7 10 7s-.712 1.662-2.253 3.367l-1.416-1.417ZM3.836 12.927v.002-.002Z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};
export const EyeOpen = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      aria-hidden="true"
      focusable="false"
    >
      <path
        fill-rule="evenodd"
        d="M12 19c-7 0-10-7-10-7s3-7 10-7 10 7 10 7-3 7-10 7Zm-8.164-6.207v.002-.002Zm.46-.864L4.256 12l.042.07c.337.568.85 1.322 1.544 2.07C7.23 15.635 9.236 17 12 17s4.77-1.364 6.16-2.86a12.39 12.39 0 0 0 1.543-2.07l.042-.07-.042-.07a12.39 12.39 0 0 0-1.544-2.07C16.77 8.365 14.764 7 12 7S7.23 8.364 5.84 9.86a12.386 12.386 0 0 0-1.543 2.07ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};
export const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
      <path
        fill="#fff"
        fill-rule="evenodd"
        d="M9.923 17.101 6 13.18 7.179 12l2.744 2.744L17.667 7l1.178 1.179z"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};

export const LangunageIcon = () => {
  return (
    <svg
      id="locale-picker-globe"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="shrink-0"
      aria-hidden="true"
    >
      <path
        d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M8 15C9.39949 15 10.534 11.866 10.534 8C10.534 4.13401 9.39949 1 8 1C6.60051 1 5.466 4.13401 5.466 8C5.466 11.866 6.60051 15 8 15Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M1.448 5.75989H14.524"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M1.448 10.2402H14.524"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};
export const SearchIcon = ({ color = "#000" }) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      xmlns="http://www.w3.org/2000/svg"
      class="fill-inherit"
      aria-hidden="true"
    >
      <path
        fill-rule="evenodd"
        fill={color}
        clip-rule="evenodd"
        d="M3.27485 8.7001C3.27485 5.42781 5.92757 2.7751 9.19985 2.7751C12.4721 2.7751 15.1249 5.42781 15.1249 8.7001C15.1249 11.9724 12.4721 14.6251 9.19985 14.6251C5.92757 14.6251 3.27485 11.9724 3.27485 8.7001ZM9.19985 0.225098C4.51924 0.225098 0.724854 4.01948 0.724854 8.7001C0.724854 13.3807 4.51924 17.1751 9.19985 17.1751C11.0802 17.1751 12.8176 16.5627 14.2234 15.5265L19.0981 20.4013C19.5961 20.8992 20.4033 20.8992 20.9013 20.4013C21.3992 19.9033 21.3992 19.0961 20.9013 18.5981L16.0264 13.7233C17.0625 12.3176 17.6749 10.5804 17.6749 8.7001C17.6749 4.01948 13.8805 0.225098 9.19985 0.225098Z"
      ></path>
    </svg>
  );
};

export const Iconsss = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      id="flag-icon-css-gb"
    >
      <defs>
        <clipPath id="a">
          <path fill-opacity=".67" d="M-85.333 0h682.67v512h-682.67z" />
        </clipPath>
      </defs>
      <g clip-path="url(#a)" transform="translate(80) scale(.94)">
        <g stroke-width="1pt">
          <path fill="#006" d="M-256 0H768.02v512.01H-256z" />
          <path
            d="M-256 0v57.244l909.535 454.768H768.02V454.77L-141.515 0H-256zM768.02 0v57.243L-141.515 512.01H-256v-57.243L653.535 0H768.02z"
            fill="#fff"
          />
          <path
            d="M170.675 0v512.01h170.67V0h-170.67zM-256 170.67v170.67H768.02V170.67H-256z"
            fill="#fff"
          />
          <path
            d="M-256 204.804v102.402H768.02V204.804H-256zM204.81 0v512.01h102.4V0h-102.4zM-256 512.01L85.34 341.34h76.324l-341.34 170.67H-256zM-256 0L85.34 170.67H9.016L-256 38.164V0zm606.356 170.67L691.696 0h76.324L426.68 170.67h-76.324zM768.02 512.01L426.68 341.34h76.324L768.02 473.848v38.162z"
            fill="#c00"
          />
        </g>
      </g>
    </svg>
  );
};

export const Arrowup = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      fill="none"
      viewBox="0 0 14 8"
      class="styles_arrow__kKRBf"
    >
      <path
        fill="#6A697C"
        fill-rule="evenodd"
        d="M1.024 6.78a1.25 1.25 0 0 0 1.757.196L7 3.601l4.22 3.375a1.25 1.25 0 1 0 1.56-1.952l-5-4a1.25 1.25 0 0 0-1.56 0l-5 4a1.25 1.25 0 0 0-.196 1.757"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};
export const Arrowdown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: "rotate(180deg)" }}
      width="14"
      height="8"
      fill="none"
      viewBox="0 0 14 8"
      class="styles_arrow__kKRBf styles_collapsed__GNX3u"
    >
      <path
        fill="#6A697C"
        fill-rule="evenodd"
        d="M1.024 6.78a1.25 1.25 0 0 0 1.757.196L7 3.601l4.22 3.375a1.25 1.25 0 1 0 1.56-1.952l-5-4a1.25 1.25 0 0 0-1.56 0l-5 4a1.25 1.25 0 0 0-.196 1.757"
        clip-rule="evenodd"
      ></path>
    </svg>
  );
};
