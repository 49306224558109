import React from "react";
import Homepage from "./pages/Homepage";
import { Routes, Route } from "react-router";
import Layout from "./layouts/layout";
import Login from "./pages/auth/Login";
import BecomeTutor from "./pages/BecomeTutor";
import FindTutor from "./pages/FindTutor";
import Cart from "./pages/Cart";
import SignUp from "./pages/auth/SignUp";
import ForgotPassword from "./pages/auth/ForgotPassword";
import HelpPage from "./pages/HelpPage";
import TeachOnline from "./pages/TeachOnline";
import TestPage from "./pages/test";
import Aboutus from "./pages/Aboutus";
import HowWorks from "./pages/HowWorks";
import TutorSignup from "./pages/auth/TutorSignup";

const Navigation = () => {
  const NotFound = () => {
    return (
      <div
        style={{ marginTop: "200px", minHeight: "50vh", textAlign: "center" }}
      >
        Not Found
      </div>
    );
  };

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Homepage />} />
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="teach/signup" element={<TutorSignup />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="teach" element={<TeachOnline />} />
        <Route path="become-tutor" element={<BecomeTutor />} />
        <Route path="online/english-tutors" element={<FindTutor />} />
        <Route path="cart" element={<Cart />} />
        <Route path="how-it-works" element={<HowWorks />} />
        <Route path="about-us" element={<Aboutus />} />
        <Route path="collection/preply-business" element={<NotFound />} />
        <Route path="collection/help-for-tutors" element={<NotFound />} />
        <Route path="collection/help-for-students" element={<NotFound />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="/help" element={<HelpPage />} />
    </Routes>
  );
};

export default Navigation;
