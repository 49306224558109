import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router";
import { Link } from "react-router";

const TeachOnline = () => {
  const navigate = useNavigate();

  const navigatetotutor = () => {
    navigate("/teach/signup");
  };

  return (
    <div className="inner-page">
      <div className="container">
        <div class="top_sec">
          <div class="RegistrationTimelineContainer-sc-1o9wz8n-2 nOiVh">
            <h1
              class="preply-ds-heading _Heading_15k8d_4 _Heading--variant-large_15k8d_399 _Heading--medium-l--variant-extraLarge_15k8d_506 _TextCentered_15gyg_2 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3"
              data-preply-ds-component="Heading"
            >
              Make a living by teaching the largest community of learners
              worldwide
            </h1>
            <div class="RegistrationTimeline-sc-1o9wz8n-3 bSxQpe">
              <div class="RegistrationTimelineItem-sc-1o9wz8n-5 gJuoju">
                <div class="Step-sc-1o9wz8n-4 cozZWW">
                  <h4
                    class="preply-ds-heading _Heading_15k8d_4 _Heading--variant-medium_15k8d_405 _TextCentered_15gyg_2 _TextAccent_igxjd_3 _TextAccent--accent-inverted_igxjd_21 _Color_y6u7n_3"
                    data-preply-ds-component="Heading"
                  >
                    1
                  </h4>
                </div>
                <div class="step_text">
                  <h4>Sign up</h4>
                  <p>to create your tutor profile</p>
                </div>
              </div>
              <div class="RegistrationTimelineItem-sc-1o9wz8n-5 gJuoju">
                <div class="Step-sc-1o9wz8n-4 cozZWW">
                  <h4
                    class="preply-ds-heading _Heading_15k8d_4 _Heading--variant-medium_15k8d_405 _TextCentered_15gyg_2 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3"
                    data-preply-ds-component="Heading"
                  >
                    2
                  </h4>
                </div>
                <div class="step_text">
                  <h4>Get approved</h4>
                  <p>by our team in 5 business days</p>
                </div>
              </div>
              <div class="RegistrationTimelineItem-sc-1o9wz8n-5 gJuoju">
                <div class="Step-sc-1o9wz8n-4 cozZWW">
                  <h4
                    class="preply-ds-heading _Heading_15k8d_4 _Heading--variant-medium_15k8d_405 _TextCentered_15gyg_2 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3"
                    data-preply-ds-component="Heading"
                  >
                    3
                  </h4>
                </div>
                <div class="step_text">
                  <h4>Start earning</h4>
                  <p>by teaching students all over the world!</p>
                </div>
              </div>
              <div class="ProgressLine-sc-1o9wz8n-6 fGhAFY"></div>
            </div>
            <div class="btn_cutom">
              <button
                data-preply-ds-component="Button"
                class="btn_new"
                type="button"
                onClick={navigatetotutor}
              >
                <span class="_ButtonBase--content_sf5ep_77">
                  Create a tutor profile now
                </span>
              </button>
            </div>
          </div>

          <div class="StackedImageContainer-sc-1o9wz8n-1 iHvQNN">
            <div
              class="styles-module_container__yaJag"
              data-preply-ds-component="RebrandStackedImage"
            >
              <img
                aria-hidden="true"
                class="styles-module_image__LAg3H styles-module_on__stMfj"
                src={require("../assets/images/teach/4.jpg")}
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="container">
          <div class="grid_type_colm">
            <div class="styles_column__Yjbhx">
              <h3 class="preply-ds-heading">Set your own rate</h3>
              <span>
                Choose your hourly rate and change it anytime. On average,
                English tutors charge $15-25 per hour.
              </span>
            </div>

            <div class="styles_column__Yjbhx">
              <h3 class="preply-ds-heading">Teach anytime, anywhere</h3>
              <span>
                Decide when and how many hours you want to teach. No minimum
                time commitment or fixed schedule. Be your own boss!
              </span>
            </div>

            <div class="styles_column__Yjbhx">
              <h3 class="preply-ds-heading">Grow professionally</h3>
              <span>
                Attend professional development webinars and get tips to upgrade
                your skills. You’ll get all the help you need from our team to
                grow.
              </span>
            </div>
          </div>
        </div>

        <div class="container">
          <div class="Teach_stud_row">
            <div class="Teach_stud_left">
              <h2 class="tittle_main">
                Teach students from over 180 countries
              </h2>
              <p class="">
                Preply tutors teach 800,000+ students globally. Join us and
                you’ll have everything you need to teach successfully.
              </p>

              <ul class="list_teach">
                <li class="styles_benefit__a1B5R">
                  <span
                    class="_Icon_1lkov_4"
                    data-preply-ds-component="Icon"
                    role="img"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-preply-ds-component="SvgTokyoUIIcon"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.707 18 4 13.293l1.414-1.414 3.293 3.293L18 5.879l1.414 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="_Text_9tdt4_4 _Text--variant-large-regular_9tdt4_92 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3 _TextCentered_15gyg_2"
                    data-preply-ds-component="Text"
                  >
                    Steady stream of new students
                  </p>
                </li>

                <li class="styles_benefit__a1B5R">
                  <span
                    class="_Icon_1lkov_4"
                    data-preply-ds-component="Icon"
                    role="img"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-preply-ds-component="SvgTokyoUIIcon"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.707 18 4 13.293l1.414-1.414 3.293 3.293L18 5.879l1.414 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="_Text_9tdt4_4 _Text--variant-large-regular_9tdt4_92 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3 _TextCentered_15gyg_2"
                    data-preply-ds-component="Text"
                  >
                    Smart calendar
                  </p>
                </li>

                <li class="styles_benefit__a1B5R">
                  <span
                    class="_Icon_1lkov_4"
                    data-preply-ds-component="Icon"
                    role="img"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-preply-ds-component="SvgTokyoUIIcon"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.707 18 4 13.293l1.414-1.414 3.293 3.293L18 5.879l1.414 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="_Text_9tdt4_4 _Text--variant-large-regular_9tdt4_92 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3 _TextCentered_15gyg_2"
                    data-preply-ds-component="Text"
                  >
                    Interactive classroom
                  </p>
                </li>

                <li class="styles_benefit__a1B5R">
                  <span
                    class="_Icon_1lkov_4"
                    data-preply-ds-component="Icon"
                    role="img"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-preply-ds-component="SvgTokyoUIIcon"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.707 18 4 13.293l1.414-1.414 3.293 3.293L18 5.879l1.414 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="_Text_9tdt4_4 _Text--variant-large-regular_9tdt4_92 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3 _TextCentered_15gyg_2"
                    data-preply-ds-component="Text"
                  >
                    Convenient payment methods
                  </p>
                </li>

                <li class="styles_benefit__a1B5R">
                  <span
                    class="_Icon_1lkov_4"
                    data-preply-ds-component="Icon"
                    role="img"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-preply-ds-component="SvgTokyoUIIcon"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.707 18 4 13.293l1.414-1.414 3.293 3.293L18 5.879l1.414 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="_Text_9tdt4_4 _Text--variant-large-regular_9tdt4_92 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3 _TextCentered_15gyg_2"
                    data-preply-ds-component="Text"
                  >
                    <span>Professional development webinars</span>
                  </p>
                </li>

                <li class="styles_benefit__a1B5R">
                  <span
                    class="_Icon_1lkov_4"
                    data-preply-ds-component="Icon"
                    role="img"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-preply-ds-component="SvgTokyoUIIcon"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8.707 18 4 13.293l1.414-1.414 3.293 3.293L18 5.879l1.414 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <p
                    class="_Text_9tdt4_4 _Text--variant-large-regular_9tdt4_92 _TextAccent_igxjd_3 _TextAccent--accent-primary_igxjd_6 _Color_y6u7n_3 _TextCentered_15gyg_2"
                    data-preply-ds-component="Text"
                  >
                    Supportive tutor community
                  </p>
                </li>
              </ul>

              <div class="btn_cutom">
                <button
                  data-preply-ds-component="Button"
                  class="btn_new"
                  type="button"
                  onClick={navigatetotutor}
                >
                  <span class="_ButtonBase--content_sf5ep_77">
                    Create a tutor profile now
                  </span>
                </button>
              </div>
            </div>

            <div class="styles_assetWrap__JMvd3">
              <div
                class="styles-module_container__yaJag"
                data-preply-ds-component="RebrandStackedImage"
              >
                <img
                  aria-hidden="true"
                  class="styles-module_image__LAg3H styles-module_on__stMfj"
                  src={require("../assets/images/teach/5.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="Preply_sec">
            <div class="styles_assetWrap__1sfVL">
              <div
                class="styles-module_container__yaJag"
                data-preply-ds-component="RebrandStackedImage"
              >
                <img
                  aria-hidden="true"
                  class="styles-module_image__LAg3H styles-module_on__stMfj"
                  src={require("../assets/images/teach/3.jpg")}
                  alt=""
                />
              </div>
            </div>

            <div class="styles_reviewWrap__WTqBL">
              <div class="Preply_content">
                <h2 class="tittle_main">
                  “Preply allowed me to make a living without leaving home!”
                </h2>
                <p>
                  <span>Krista A.</span> ・ English tutor
                </p>
              </div>

              <div class="btn_cutom">
                <button
                  data-preply-ds-component="Button"
                  class="btn_new"
                  type="button"
                  onClick={navigatetotutor}
                >
                  <span class="_ButtonBase--content_sf5ep_77">
                    Create a tutor profile now
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="Frequently container">
          <h2 class="tittle_main">Frequently asked questions</h2>
          <div className="accordion-container">
            <Accordion flush alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header style={{ fontWeight: 600 }}>
                  What kind of tutors does Preply look for?
                </Accordion.Header>
                <Accordion.Body>
                  No specific certification or teaching experience is required!
                  We welcome tutors who:
                  <ul>
                    <li>
                      Enjoy sharing knowledge and making a difference in
                      students’ lives
                    </li>
                    <li>Have outstanding communication skills</li>
                    <li>
                      Are willing to provide a personalized learning experience
                      to international students
                    </li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header style={{ fontWeight: 600 }}>
                  What subject can I teach?
                </Accordion.Header>
                <Accordion.Body>
                  We have over 100 subjects on Preply, including languages,
                  school and university subjects, hobbies and art.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header style={{ fontWeight: 600 }}>
                  How do I become an online tutor at Preply?
                </Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>Provide some basic information about yourself</li>
                    <li>Upload your headshot photo</li>
                    <li>Describe your strengths as a tutor</li>
                    <li>
                      Record a short video introduction (up to 2 mins long)
                    </li>
                    <li>Choose your availability</li>
                  </ol>
                  You’ll see tips and examples at each step of the registration
                  process to help you create a great tutor profile. When you
                  complete registration, our Tutor Success team will review your
                  profile within 5 business days. Once your profile is approved,
                  students from around the world will see it on Preply and will
                  be able to book lessons with you.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header style={{ fontWeight: 600 }}>
                  How can I get my profile approved quickly?
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    Sometimes, we don’t approve tutor profiles at the first
                    attempt because the profile photo, video or description
                    doesn’t meet our requirements. Make sure you use a real
                    photo of yourself, take the time to record a short video and
                    describe your strengths as a tutor in the description. A
                    surefire way to get approved is to follow the tips given at
                    each step of the registration process
                  </div>
                  <div>
                    Also, be sure to avoid mentioning any contact details,
                    lesson prices or misleading information in your profile
                    description and video. We’ll ask you to remove this
                    information, which will slow down the approval process.
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header style={{ fontWeight: 600 }}>
                  Why should I teach on Preply?
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    Because it’s easy and flexible! If you teach with Preply,
                    you:
                  </div>
                  <ul>
                    <li>earn by sharing what you know</li>
                    <li>
                      get a steady stream of new students looking to learn
                      online
                    </li>
                    <li>
                      manage your lessons and connect with students easily
                    </li>
                    <li>teach whenever and wherever you want</li>
                    <li>
                      use safe payment methods (Paypal, Payoneer, Skrill or
                      Transferwise)
                    </li>
                    <li>
                      get support from our friendly team through professional
                      development webinars, live chat and email
                    </li>
                    <li>
                      join a community of expert tutors who are always there for
                      you
                    </li>
                  </ul>
                  <div>
                    ...and more to come! We’re constantly improving the platform
                    and teaching tools for our tutors based on their needs.
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header style={{ fontWeight: 600 }}>
                  What computer equipment do I need to teach on Preply?
                </Accordion.Header>
                <Accordion.Body>
                  You will need a laptop or a desktop computer, a stable
                  internet connection, a webcam, and a microphone for conducting
                  lessons in the Preply virtual classroom
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header style={{ fontWeight: 600 }}>
                  Is it free to create a tutor profile on Preply?
                </Accordion.Header>
                <Accordion.Body>
                  Yes. It is free to create a tutor profile, get exposure to
                  students, and use Preply’s tools and materials. We only charge
                  a commission for the lessons that you have taught. The
                  commission for a trial lesson with a new student is 100%. The
                  commission for the subsequent lessons starts at 33% and
                  decreases to 18%: the more hours you teach on Preply, the
                  lower the rate of commission.{" "}
                  <Link to={"/help"} style={{ color: "#000" }}>
                    Learn more about Preply’s commission model
                  </Link>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header style={{ fontWeight: 600 }}>
                  How much can I earn on Preply?
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    Most popular tutors on Preply earn up to $550 a week. Your
                    earnings depend on the hourly rate you set, the number of
                    lessons you teach and how many students continue learning
                    with you after the trial lesson. A tip for newly registered
                    tutors: start with a lower hourly rate to get first students
                    faster. You can change your rate anytime. Preply takes a
                    commission fee from your lessons to bring in more students
                    from around the globe, and develop an easy-to-use video tool
                    and learning materials for your lessons. We provide free
                    professional development webinars and multilingual customer
                    support to guide you along every step of your tutoring
                    journey.
                  </div>
                  <div>
                    The commission for every trial lesson with a new student is
                    100%. For all subsequent lessons, the commission starts at
                    33% and decreases to 18% based on how many hours you’ve
                    taught on the platform.
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
          <div style={{ textAlign: "center" }}>
            Have more questions?{" "}
            <Link to={"/help"} style={{ color: "#000" }}>
              Check our Help center
            </Link>{" "}
            or{" "}
            <Link to={"/help"} style={{ color: "#000" }}>
              contact our support team
            </Link>
          </div>
        </div>
        <div className="gat_sec">
          <div class="container styles_section__A5WVx">
            <div class="styles_mainWrap__EWHEr">
              <div class="styles_assetWrap__7a4la">
                <img src={require("../assets/images/teach/2.jpg")} />
              </div>
              <div class="styles_textWrapper__JCNiJ">
                <div
                  class="Get-paid"
                  data-ds-direction="column"
                  data-preply-ds-component="LayoutFlex"
                >
                  <h2 class="tittle_main">Get paid to teach online</h2>
                  <p class="">
                    Connect with thousands of learners around the world and
                    teach from your living room
                  </p>
                  <div class="btn_cutom">
                    <button
                      data-preply-ds-component="Button"
                      class="btn_new"
                      type="button"
                      onClick={navigatetotutor}
                    >
                      <span class="_ButtonBase--content_sf5ep_77">
                        Create a tutor profile now
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeachOnline;
