import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import Slider from "react-slick";

let settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  dragable: false,
  swipe: false,
};

const options = [
  { value: "english", label: "English" },
  { value: "spanish", label: "Spanish" },
  { value: "french", label: "French" },
  { value: "german", label: "German" },
  { value: "chinese", label: "Chinese" },
  { value: "japanese", label: "Japanese" },
  { value: "italian", label: "Italian" },
  { value: "portuguese", label: "Portuguese" },
  { value: "russian", label: "Russian" },
  { value: "arabic", label: "Arabic" },
  { value: "hindi", label: "Hindi" },
  { value: "bengali", label: "Bengali" },
  { value: "urdu", label: "Urdu" },
  { value: "korean", label: "Korean" },
  { value: "turkish", label: "Turkish" },
  { value: "vietnamese", label: "Vietnamese" },
  { value: "polish", label: "Polish" },
  { value: "dutch", label: "Dutch" },
  { value: "swedish", label: "Swedish" },
  { value: "finnish", label: "Finnish" },
  { value: "greek", label: "Greek" },
  { value: "hungarian", label: "Hungarian" },
  { value: "hebrew", label: "Hebrew" },
  { value: "thai", label: "Thai" },
  { value: "czech", label: "Czech" },
  { value: "swahili", label: "Swahili" },
  { value: "tagalog", label: "Tagalog" },
  { value: "malay", label: "Malay" },
  { value: "indonesian", label: "Indonesian" },
  { value: "persian", label: "Persian" },
  { value: "kurdish", label: "Kurdish" },
  { value: "dari", label: "Dari" },
  { value: "nepali", label: "Nepali" },
  { value: "javanese", label: "Javanese" },
];

const items = [
  {
    name: "Amanda B.",
    image: require("../assets/images/avatar.jpg"),
    star: "4.98",
    price: "3,056",
    language: "English",
    activeusers: "20",
    lessons: "488",
    nativelang: "English",
    exp: "Certified Native English Tutor - 15 years experience",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— I am a highly qualified Cambridge University English teacher for adults and children age 7 +.",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-6.jpg"),
    star: "5",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-2.jpg"),
    star: "6",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-3.jpg"),
    star: "7",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-6.jpg"),
    star: "5",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-4.jpg"),
    star: "8",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-5.jpg"),
    star: "9",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-6.jpg"),
    star: "10",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-3.jpg"),
    star: "11",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
  {
    name: "Claire B.",
    image: require("../assets/images/avatar-2.jpg"),
    star: "12",
    price: "1,956",
    language: "English",
    activeusers: "40",
    lessons: "3410",
    nativelang: "English",
    exp: "Native English speaker certified in adult English language education ",
    country: require("../assets/images/avatar.jpg"),
    about:
      "— Hi! My name is Claire, and I'm so excited to meet you. I grew up in Michigan, lived in",
  },
];

function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <div class="oppor_sm_grid">
            <div class="oppor_sub_grid teachers_available">
              <div class="oppor_left_block">
                <img src={item.image} />
              </div>
              <div class="oppor_right_grid">
                <div class="oppor_name_flag">
                  <a href="#" class="styles_link__k_pxX">
                    <p>{item.name}</p>
                  </a>
                  <span
                    class="_Icon_1lkov_4 _TextAccent_igxjd_3 _Color_y6u7n_3"
                    data-preply-ds-component="Icon"
                    role="img"
                    aria-hidden="true"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-preply-ds-component="SvgTokyoUIIcon"
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      focusable="false"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.291 4.055 12 2 8.709 4.055l-3.78.874-.874 3.78L2 12l2.055 3.291.874 3.78 3.78.874L12 22l3.291-2.055 3.78-.874.874-3.78L22 12l-2.055-3.291-.874-3.78zM9.793 15.707l.707.707.707-.707 6-6-1.414-1.414-5.293 5.293-2.293-2.293-1.414 1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </span>

                  <img
                    src={item.country}
                    alt="United Kingdom"
                    width="16px"
                    class="styles-module_root__FKhpE styles-module_sizeXS__BVGqr"
                    data-preply-ds-component="RebrandFlag"
                  />
                </div>
                <div class="pro_btn">
                  <a href="#">Professional</a>
                  <a class="Super_Tutor" href="#">
                    Super Tutor
                  </a>
                </div>

                <div class="lang_age">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path d="m12 21-7-3.8v-6L1 9l11-6 11 6v8h-2v-6.9l-2 1.1v6zm0-8.3L18.85 9 12 5.3 5.15 9zm0 6.025 5-2.7V12.25L12 15l-5-2.75v3.775z"></path>
                  </svg>
                  <p>{item.language}</p>
                </div>

                <div class="stud_ents">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6M7 8a5 5 0 1 1 10 0A5 5 0 0 1 7 8m13 13v-6H4v6z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p>
                    {item.activeusers} active students • {item.lessons} lessons
                  </p>
                </div>
                <div class="Speaks Speak_row">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3H8v2H3v2h8.015a8.5 8.5 0 0 1-1.314 4.286L6.707 8.293 5.293 9.707l3.166 3.166c-.297.312-.606.6-.918.865a14.3 14.3 0 0 1-3.565 2.196 8 8 0 0 1-.297.119l-.012.004L4 17l.334.943.004-.002.007-.002.025-.01a8 8 0 0 0 .383-.152 16.3 16.3 0 0 0 4.081-2.514q.527-.445 1.039-.976l1.42 1.42 1.414-1.414-1.563-1.563A10.5 10.5 0 0 0 13.017 7H15V5h-5zm8.253 17-.597-1.596H14.34L13.74 20H11.7l3.264-8.4h2.064l3.265 8.4zm-2.255-6.027 1.02 2.727h-2.041z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <p>Speaks {item.nativelang} (Native)</p>
                </div>
                <strong>
                  {item.exp}
                  <p>{item.about}</p>
                  <a href="#">Read More</a>
                </strong>
              </div>
              <div class="pri_rat_avai">
                <div class="price_rating">
                  <div class="right_rate">
                    <h4>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        data-preply-ds-component="SvgTokyoUIIcon"
                        viewBox="0 0 24 24"
                        aria-hidden="true"
                        focusable="false"
                      >
                        <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                      </svg>
                      {item.star}
                    </h4>
                    <p>{item?.review || "test"} reviews</p>
                  </div>
                  <div class="price_rate">
                    <h4>₹{item.price}</h4>
                    <p>per lesson</p>
                  </div>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.899 5.94a4.913 4.913 0 1 1 6.835 7.058l-7.736 7.252-7.735-7.252a4.913 4.913 0 1 1 6.835-7.059l.9.9zm5.534 1.413a2.913 2.913 0 0 0-4.12 0l-2.315 2.315-2.315-2.315a2.913 2.913 0 1 0-4.052 4.186l6.367 5.97 6.368-5.97a2.913 2.913 0 0 0 .067-4.186"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
                <div class="pro_btn_botm">
                  <a href="#">Book trial lesson</a>
                  <a class="Super_Tutor" href="#">
                    Send message
                  </a>
                </div>
              </div>
            </div>

            <div class="Recommended_row">
              <div class="Recommended_left">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  data-preply-ds-component="SvgTokyoUIIcon"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 3H7v2H3v15h18V5h-4V3h-3v2h-4zm4 5V7h-4v1H7V7H5v11h14V7h-2v1zm-5 2H7v2h2zm-2 4h2v2H7zm6 0h-2v2h2zm-2-4h2v2h-2zm6 0h-2v2h2z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <p>Recommended time slots for tomorrow</p>
              </div>
              <div class="Recommended_right">
                <a href="#">19:30</a>
                <a href="#">19:30</a>
                <a href="#">19:30</a>
                <a class="View_Mor" href="#">
                  View More
                </a>
              </div>
            </div>
          </div>
        ))}
    </>
  );
}

const FindTutor = () => {
  let itemsPerPage = 2;

  const [totalpages, setTotalPages] = useState();
  const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(items.length / itemsPerPage);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="inner-page">
      <div class="Online_sec">
        <div class="container Online_row">
          <div class="Online_left">
            <h1 class="sub_head">
              Online English tutors & teachers for private lessons
            </h1>
            <p>
              Looking for an online English tutor? Preply is the leading online
              language learning platform...
              <a class="" data-preply-ds-component="Link">
                Read more
              </a>
              .
            </p>
            <p class="get_a">
              Get a personalized choice of tutors by answering a few quick
              questions
            </p>

            <div class="leble_field_top">
              <div style={{ flex: 1 }}>
                <Select options={options} placeholder="Search..."  styles={{}}/>
              </div>
              <button>
                Get started
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  data-preply-ds-component="SvgTokyoUIIcon"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    fill-rule="evenodd"
                    d="M16.086 10.993h-12v2h12l-5.293 5.293 1.414 1.414 7.707-7.707-7.707-7.707L10.793 5.7zm1 1"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div class="online_right_bnr">
            <div class="styles-module_container__yaJag">
              <img src={require("../assets/english-tutor/1.png")} alt="anim" />
            </div>
          </div>
        </div>
      </div>
      <div class="field_sec">
        <div class="container">
          <div class="field_row">
            <div
              class="styles_DesktopFilterWrapBase__XY7ze"
              role="searchbox"
              tabindex="0"
              data-qa-id="desktop-searchbox"
            >
              <span class="styles_FilterLabel__CbnBD styles_TextWrap__GMkwL">
                <span>I want to learn</span>
              </span>

              <label class="styles_AutocompleteWrap__n4cMO">
                <div style={{ width: "100%" }}>
                  {/* <Select options={options} /> */}
                  <div class="styles_AutocompleteBody__VV9BN">
                    <input
                      id="AutocompleteSelectInput"
                      class="styles_Input__JJSbB tutor-input"
                      type="text"
                      data-qa-id="subject-input-field"
                      placeholder="English"
                      autocomplete="off"
                      value="English"
                    />
                    <button
                      class="styles_ClearIconButton__L_aHO"
                      data-qa-id="filters-clean-btn"
                    >
                      <svg
                        height="12"
                        viewBox="0 0 12 12"
                        width="12"
                        xmlns="http://www.w3.org/2000/svg"
                        class="styles_ClearIcon__waOso"
                      >
                        <path d="M6 4.586L10.293.293l1.414 1.414L7.414 6l4.293 4.293-1.414 1.414L6 7.414l-4.293 4.293-1.414-1.414L4.586 6 .293 1.707 1.707.293z"></path>
                      </svg>
                    </button>
                  </div>
                </div>
              </label>
            </div>

            <div
              class="styles_DesktopFilterWrapBase__XY7ze styles_FilterPopupWrapper__lMwSJ"
              data-qa-group="search-desktop-top-filter"
            >
              <span class="styles_FilterLabel__OntBU styles_TextWrap__oaDCr">
                Price per lesson
              </span>

              <div
                class="per_lesson_box"
                data-ds-nowrap="true"
                data-ds-padding="none"
                data-ds-direction="row"
                data-ds-alignitems="center"
                data-ds-justifycontent="space-between"
                data-preply-ds-component="LayoutFlex"
              >
                <div class="styles_FilterValueWrapper__bHu4E">
                  <span class="styles_FilterValue__qMzgd styles_TextWrap__oaDCr">
                    <span data-qa-id="price-filter">
                      {/* <span style="text-wrap:nowrap">₹<!-- -->100<!-- --> – <!-- -->₹<!-- -->3<!-- -->,<!-- -->400<!-- -->+</span> */}
                    </span>
                  </span>
                  <div></div>
                </div>
                <span
                  class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                  data-preply-ds-component="Icon"
                  role="img"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m12 16-6-5.61L7.487 9 12 13.22 16.513 9 18 10.39z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div
              class="styles_DesktopFilterWrapBase__XY7ze styles_FilterPopupWrapper__lMwSJ"
              data-qa-group="search-desktop-top-filter"
            >
              <span class="styles_FilterLabel__OntBU styles_TextWrap__oaDCr">
                Country of birth
              </span>

              <div
                class="per_lesson_box"
                data-ds-nowrap="true"
                data-ds-padding="none"
                data-ds-direction="row"
                data-ds-alignitems="center"
                data-ds-justifycontent="space-between"
                data-preply-ds-component="LayoutFlex"
              >
                <div class="styles_FilterValueWrapper__bHu4E">
                  <span class="styles_FilterValue__qMzgd styles_TextWrap__oaDCr">
                    <span>Any country</span>
                  </span>
                  <div></div>
                </div>
                <span
                  class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                  data-preply-ds-component="Icon"
                  role="img"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m12 16-6-5.61L7.487 9 12 13.22 16.513 9 18 10.39z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div
              class="styles_DesktopFilterWrapBase__XY7ze styles_FilterPopupWrapper__lMwSJ"
              data-qa-group="search-desktop-top-filter"
            >
              <span class="styles_FilterLabel__OntBU styles_TextWrap__oaDCr">
                I'm available
              </span>

              <div
                class="per_lesson_box"
                data-ds-nowrap="true"
                data-ds-padding="none"
                data-ds-direction="row"
                data-ds-alignitems="center"
                data-ds-justifycontent="space-between"
                data-preply-ds-component="LayoutFlex"
              >
                <div class="styles_FilterValueWrapper__bHu4E">
                  <span class="styles_FilterValue__qMzgd styles_TextWrap__oaDCr">
                    <span>Any time</span>
                  </span>
                  <div></div>
                </div>
                <span
                  class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                  data-preply-ds-component="Icon"
                  role="img"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m12 16-6-5.61L7.487 9 12 13.22 16.513 9 18 10.39z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div class="drop_downs">
            <div class="styles_DesktopFiltersBottomGroup__BX_EI">
              <div
                data-qa-id="specialties"
                class="styles_FilterWrapper__nn3Hz styles_FilterWrapperBgColorWhite__mDrBS"
                data-qa-group="search-desktop-bottom-filter"
              >
                <span class="styles_FilterLabel__aAj3y styles_TextWrap__4K0XS">
                  Specialties
                </span>
                <span
                  class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                  data-preply-ds-component="Icon"
                  role="img"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m12 16-6-5.61L7.487 9 12 13.22 16.513 9 18 10.39z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>

              <div
                data-qa-id="also-speaks-filter"
                class="styles_FilterWrapper__nn3Hz styles_FilterWrapperBgColorWhite__mDrBS"
                data-qa-group="search-desktop-bottom-filter"
              >
                <span class="styles_FilterLabel__aAj3y styles_TextWrap__4K0XS">
                  Also speaks
                </span>
                <span
                  class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                  data-preply-ds-component="Icon"
                  role="img"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m12 16-6-5.61L7.487 9 12 13.22 16.513 9 18 10.39z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>

              <div
                data-qa-id="native-speaker-filter"
                class="styles_FilterWrapper__nn3Hz styles_FilterWrapperBgColorWhite__mDrBS"
                data-qa-group="search-desktop-bottom-filter"
              >
                <span class="styles_FilterLabel__aAj3y styles_TextWrap__4K0XS">
                  Native speaker
                </span>
                <span
                  class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                  data-preply-ds-component="Icon"
                  role="img"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m12 16-6-5.61L7.487 9 12 13.22 16.513 9 18 10.39z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>

              <div
                data-qa-id="additional-tutor-filter"
                class="styles_FilterWrapper__nn3Hz styles_FilterWrapperBgColorWhite__mDrBS"
                data-qa-group="search-desktop-bottom-filter"
              >
                <span class="styles_FilterLabel__aAj3y styles_TextWrap__4K0XS">
                  <span>Tutor categories</span>
                </span>
                <span
                  class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                  data-preply-ds-component="Icon"
                  role="img"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m12 16-6-5.61L7.487 9 12 13.22 16.513 9 18 10.39z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>
            </div>

            <div class="styles_DesktopFiltersBottomGroup__BX_EI">
              <div
                data-qa-id="sort-by-filter"
                class="styles_FilterWrapper__nn3Hz styles_FilterWrapperBgColorWhite__mDrBS"
                data-qa-group="search-desktop-bottom-filter"
              >
                <span class="styles_FilterLabel__aAj3y styles_TextWrap__4K0XS">
                  Sort by: <span>Our top picks</span>
                </span>
                <span
                  class="_Icon_1lkov_4 _Icon--size-s_1lkov_28 _TextAccent_igxjd_3 _Color_y6u7n_3"
                  data-preply-ds-component="Icon"
                  role="img"
                  aria-hidden="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    data-preply-ds-component="SvgTokyoUIIcon"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                    focusable="false"
                  >
                    <path
                      fill-rule="evenodd"
                      d="m12 16-6-5.61L7.487 9 12 13.22 16.513 9 18 10.39z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </span>
              </div>

              <div class="styles_AutocompleteWrapper__EWmPp">
                <div class="styles_InputWrapper__4grJo">
                  <div class="styles_SearchIconWrapper__Mb8Zb">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="16"
                      fill="none"
                      viewBox="0 0 15 16"
                      class="styles_SearchIcon__X9MSB"
                    >
                      <path
                        fill="#3BB2BD"
                        fill-rule="evenodd"
                        d="M6.5.642a6.5 6.5 0 0 1 5.249 10.335l2.958 2.958-1.414 1.415-2.958-2.959A6.5 6.5 0 1 1 6.5.642m0 2a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    size="250"
                    class="styles_Input__2vXp_"
                    placeholder="Search by name or keyword"
                    value=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="English_teachers">
        <div class="container">
          <div class="styles_ResultsWrapper__Z63be">
            <h3 class="small_head" style={{ marginBottom: "20px" }}>
              {items.length} English teachers available
            </h3>
          </div>

          <div
            class="styles_ResultsWrapper__Z63be"
            data-qa-id="tutor-list-container"
          >
            <div
              class="styles_SideWrapper__X6Jkp"
              style={{ transform: "translateY(0px)" }}
            >
              <div
                class="styles_PanelWrapper__UNA3W"
                data-qa-id="side-availability-panel"
              >
                <div class="styles_VideoWrapper__cop6g">
                  <div
                    class="styles_VideoPreview__RpVJe"
                    role="button"
                    tabindex="0"
                  >
                    <img
                      fetchpriority="high"
                      src={require("../assets/images/videothumb.jpg")}
                      class="styles_VideoThumbnail__uIXkh"
                      alt="Video preview"
                    />
                    <div
                      class="styles_VideoBtn__0_1NQ"
                      data-qa-id="open-video-btn"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="opportunities_block">
              <Items currentItems={currentItems} />
              <div style={{ width: "60%", margin: "auto" }}>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  className="tutor-pagination"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="<"
                  renderOnZeroPageCount={null}
                />
              </div>

              {/* <ul
                class="Pager_container__wdRC6"
                data-qa-id="search-results-pager"
              >
                <li>
                  <a
                    tabindex="-1"
                    role="button"
                    aria-label="page-1"
                    class="Pager_button__OfWYm Pager_active__rF7Up"
                  >
                    <span
                      class="_Text_9tdt4_4 _Text--variant-small-semibold_9tdt4_44 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                      data-preply-ds-component="Text"
                    >
                      1
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    tabindex="0"
                    role="button"
                    aria-label="page-2"
                    class="Pager_button__OfWYm"
                  >
                    <span
                      class="_Text_9tdt4_4 _Text--variant-small-semibold_9tdt4_44 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                      data-preply-ds-component="Text"
                    >
                      2
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    tabindex="0"
                    role="button"
                    aria-label="Jump forward"
                    class="Pager_button__OfWYm"
                  >
                    <span
                      class="_Text_9tdt4_4 _Text--variant-small-semibold_9tdt4_44 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                      data-preply-ds-component="Text"
                    >
                      …
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    tabindex="0"
                    role="button"
                    aria-label="page-2812"
                    class="Pager_button__OfWYm"
                  >
                    <span
                      class="_Text_9tdt4_4 _Text--variant-small-semibold_9tdt4_44 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                      data-preply-ds-component="Text"
                    >
                      2812
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    tabindex="0"
                    role="button"
                    aria-label="Next page"
                    class="Pager_button__OfWYm"
                  >
                    <span
                      class="_Text_9tdt4_4 _Text--variant-small-semibold_9tdt4_44 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                      data-preply-ds-component="Text"
                    >
                      <span
                        class="right_angle"
                        data-preply-ds-component="Icon"
                        role="img"
                        style={{ width: "16px", height: "16px" }}
                        aria-hidden="true"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgIcon"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                          focusable="false"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M9.293 3.293a1 1 0 0 0 0 1.414L16.586 12l-7.293 7.293a1 1 0 1 0 1.414 1.414l8-8a1 1 0 0 0 0-1.414l-8-8a1 1 0 0 0-1.414 0"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </span>
                  </a>
                </li>
              </ul> */}

              <div class="faqs_sec">
                <div class="" style={{ margin: "50px 0px" }}>
                  <h3 class="small_head">How Preply works</h3>
                  <div style={{ width: "100%", marginTop: "30px" }}>
                    <Accordion flush style={{ margin: "0px" }}>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header style={{ fontWeight: 600 }}>
                          1. Find the best bengali tutor
                        </Accordion.Header>
                        <Accordion.Body>
                          Choose from over 61 bengali tutors. Use filters to
                          narrow your search and find the perfect fit.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header style={{ fontWeight: 600 }}>
                          2. Book your first lesson
                        </Accordion.Header>
                        <Accordion.Body>
                          Find the perfect time in your schedule and connect
                          with your tutor in our virtual classroom.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header style={{ fontWeight: 600 }}>
                          3. Subscribe and learn regularly
                        </Accordion.Header>
                        <Accordion.Body>
                          Get a subscription and build a learning habit! You can
                          also try another tutor for free to be sure about your
                          choice.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                  {/* <div class="accordion js-accordion">
                    <div class="accordion__item js-accordion-item active">
                      <div class="accordion-header js-accordion-header">
                        1. Find the best english tutor
                      </div>
                      <div class="accordion-body js-accordion-body">
                        <div
                          class="accordion-body__contents"
                          style={{ display: "block" }}
                        >
                          <p>
                            Choose from over 28,112 english tutors. Use filters
                            to narrow your search and find the perfect fit.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion__item js-accordion-item">
                      <div class="accordion-header js-accordion-header">
                        2. Book your first lesson{" "}
                      </div>
                      <div class="accordion-body js-accordion-body">
                        <div class="accordion-body__contents">
                          <p>
                            {" "}
                            Find the perfect time in your schedule and connect
                            with your tutor in our virtual classroom.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="accordion__item js-accordion-item">
                      <div class="accordion-header js-accordion-header">
                        3. Subscribe and learn regularly
                      </div>
                      <div class="accordion-body js-accordion-body">
                        <div class="accordion-body__contents">
                          <p>
                            Get a subscription and build a learning habit! You
                            can also try another tutor for free to be sure about
                            your choice.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div class="Why_Preply_row">
                <h3 class="small_head">
                  Why Preply is the best site to learn English online
                </h3>
                <div class="Why_Preply">
                  <div class="Why_Preply_grid">
                    <h3 class="small_head">
                      4.9/5
                      <div
                        class=" _LayoutGap_1ydxs_442 _LayoutHide_1f3it_164 _LayoutRelative_14cm4_46"
                        data-ds-nowrap="true"
                        data-ds-padding="none"
                        data-ds-direction="row"
                        data-ds-alignitems="center"
                        data-preply-ds-component="LayoutFlex"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>
                    </h3>
                    <p>
                      english tutors with an average rating of 4.9 and up to 352
                      reviews
                    </p>
                  </div>

                  <div class="Why_Preply_grid">
                    <h3 class="small_head">
                      3 languages
                      <div
                        class=" _LayoutGap_1ydxs_442 _LayoutHide_1f3it_164 _LayoutRelative_14cm4_46"
                        data-ds-nowrap="true"
                        data-ds-padding="none"
                        data-ds-direction="row"
                        data-ds-alignitems="center"
                        data-preply-ds-component="LayoutFlex"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>
                    </h3>
                    <p>
                      english tutors with an average rating of 4.9 and up to 352
                      reviews
                    </p>
                  </div>

                  <div class="Why_Preply_grid">
                    <h3 class="small_head">
                      2 h
                      <div
                        class=" _LayoutGap_1ydxs_442 _LayoutHide_1f3it_164 _LayoutRelative_14cm4_46"
                        data-ds-nowrap="true"
                        data-ds-padding="none"
                        data-ds-direction="row"
                        data-ds-alignitems="center"
                        data-preply-ds-component="LayoutFlex"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>
                    </h3>
                    <p>
                      english tutors with an average rating of 4.9 and up to 352
                      reviews
                    </p>
                  </div>
                </div>
              </div>

              <div class="Our_English_block ">
                <h3 class="small_head">Our English students love us</h3>
                <div class="English_block_rows">
                  {" "}
                  {/***English_block_row */}
                  <Slider {...settings}>
                    <div class="Our_English_colume" style={{ width: "332px" }}>
                      <div class="img_text">
                        <div class="profile_pic">
                          <img src={require("../assets/images/avatar-3.jpg")} />
                        </div>
                        <div class="prof_name">
                          <p>Evelyn </p>
                          <span>
                            with <a href="#">Tanya</a>{" "}
                          </span>
                        </div>
                      </div>
                      <div class="rating">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>

                      <div class="discrip">
                        <p>
                          Tanya is amazing, she adapts everything 100 percent
                          for you and your language progress. She is patient and
                          kind and always boosts your confidence in speaking!!!
                        </p>
                      </div>
                    </div>

                    <div class="Our_English_colume">
                      <div class="img_text">
                        <div class="profile_pic">
                          <img src={require("../assets/images/avatar-2.jpg")} />
                        </div>
                        <div class="prof_name">
                          <p>Evelyn </p>
                          <span>
                            with <a href="#">Tanya</a>{" "}
                          </span>
                        </div>
                      </div>
                      <div class="rating">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>

                      <div class="discrip">
                        <p>
                          Tanya is amazing, she adapts everything 100 percent
                          for you and your language progress. She is patient and
                          kind and always boosts your confidence in speaking!!!
                        </p>
                      </div>
                    </div>
                    <div class="Our_English_colume" style={{ width: "332px" }}>
                      <div class="img_text">
                        <div class="profile_pic">
                          <img src={require("../assets/images/avatar-3.jpg")} />
                        </div>
                        <div class="prof_name">
                          <p>Evelyn </p>
                          <span>
                            with <a href="#">Tanya</a>{" "}
                          </span>
                        </div>
                      </div>
                      <div class="rating">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>

                      <div class="discrip">
                        <p>
                          Tanya is amazing, she adapts everything 100 percent
                          for you and your language progress. She is patient and
                          kind and always boosts your confidence in speaking!!!
                        </p>
                      </div>
                    </div>
                    <div class="Our_English_colume">
                      <div class="img_text">
                        <div class="profile_pic">
                          <img src={require("../assets/images/avatar-4.jpg")} />
                        </div>
                        <div class="prof_name">
                          <p>Evelyn </p>
                          <span>
                            with <a href="#">Tanya</a>{" "}
                          </span>
                        </div>
                      </div>
                      <div class="rating">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>

                      <div class="discrip">
                        <p>
                          Tanya is amazing, she adapts everything 100 percent
                          for you and your language progress. She is patient and
                          kind and always boosts your confidence in speaking!!!
                        </p>
                      </div>
                    </div>

                    <div class="Our_English_colume">
                      <div class="img_text">
                        <div class="profile_pic">
                          <img src={require("../assets/images/avatar-5.jpg")} />
                        </div>
                        <div class="prof_name">
                          <p>Evelyn </p>
                          <span>
                            with <a href="#">Tanya</a>{" "}
                          </span>
                        </div>
                      </div>
                      <div class="rating">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>

                      <div class="discrip">
                        <p>
                          Tanya is amazing, she adapts everything 100 percent
                          for you and your language progress. She is patient and
                          kind and always boosts your confidence in speaking!!!
                        </p>
                      </div>
                    </div>
                    <div class="Our_English_colume">
                      <div class="img_text">
                        <div class="profile_pic">
                          <img src={require("../assets/images/avatar-4.jpg")} />
                        </div>
                        <div class="prof_name">
                          <p>Evelyn </p>
                          <span>
                            with <a href="#">Tanya</a>{" "}
                          </span>
                        </div>
                      </div>
                      <div class="rating">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          data-preply-ds-component="SvgTokyoUIIcon"
                          viewBox="0 0 24 24"
                        >
                          <path d="m12 3 2.221 5.942 6.338.277-4.965 3.95 1.696 6.112L12 15.78l-5.29 3.501 1.695-6.113-4.965-3.95 6.338-.276z"></path>
                        </svg>
                      </div>

                      <div class="discrip">
                        <p>
                          Tanya is amazing, she adapts everything 100 percent
                          for you and your language progress. She is patient and
                          kind and always boosts your confidence in speaking!!!
                        </p>
                      </div>
                    </div>
                  </Slider>
                </div>
              </div>

              <div class="styles_NewInterlinkingSection__gfBNl">
                <section class="styles_InterlinkingBlock__flM_Q">
                  <p class="styles_NewName__ppZun styles_open__ZsOOQ">
                    Popular languages
                  </p>
                  <ul class="styles_list__6g4jz">
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Japanese tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Arabic tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        German tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Spanish tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Korean tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        French tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Portuguese tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Italian tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Chinese tutors
                      </a>
                    </li>
                  </ul>
                </section>

                <section class="styles_InterlinkingBlock__flM_Q">
                  <p class="styles_NewName__ppZun styles_open__ZsOOQ">
                    English language specialties
                  </p>
                  <ul class="styles_list__6g4jz">
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        ESL tutors online
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        American English classes online
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Canadian English classes online
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Australian English classes online
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Intermediate English tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Advanced English tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English homework help
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Business English tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Native English speaking tutors
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Intensive English courses
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English lessons for kids
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Conversational English classes
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English tutors for beginners
                      </a>
                    </li>
                  </ul>
                </section>

                <section class="styles_InterlinkingBlock__flM_Q">
                  <p class="styles_NewName__ppZun styles_open__ZsOOQ">
                    English language test preparation
                  </p>
                  <ul class="styles_list__6g4jz">
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        TEFL prep
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        PTE prep
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        CELPIP prep
                      </a>
                    </li>
                  </ul>
                </section>

                <section class="styles_InterlinkingBlock__flM_Q">
                  <p class="styles_NewName__ppZun styles_open__ZsOOQ">
                    Find English tutors near you
                  </p>

                  <ul class="styles_list__6g4jz">
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English tutors near me in Canada
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English tutors near me in United Kingdom
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English tutors near me in USA
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English tutors near me in Australia
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English classes in Sydney
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English classes in London
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English classes in Toronto
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English classes in Houston, TX
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English classes in New York, NY
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        English classes in Los Angeles, CA
                      </a>
                    </li>
                  </ul>
                </section>

                <section class="styles_InterlinkingBlock__flM_Q">
                  <p class="styles_NewName__ppZun styles_open__ZsOOQ">
                    Learn with online courses
                  </p>
                  <ul class="styles_list__6g4jz">
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Learn English online
                      </a>
                    </li>
                  </ul>
                </section>
                <section class="styles_InterlinkingBlock__flM_Q">
                  <p class="styles_NewName__ppZun styles_open__ZsOOQ">
                    Online English classes and courses
                  </p>
                  <ul class="styles_list__6g4jz">
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Online English classes
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Online Japanese classes
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Online French classes
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Online German classes
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Online Spanish classes
                      </a>
                    </li>
                  </ul>
                </section>
                <section class="styles_InterlinkingBlock__flM_Q">
                  <p class="styles_NewName__ppZun styles_open__ZsOOQ">
                    Other languages
                  </p>
                  <ul class="styles_list__6g4jz">
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Englisch-Privatunterricht
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Aulas de inglês online
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="/pl/online/korepetycje-z-angielskiego"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Lekcje angielskiego online
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Clases de inglés online
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Cours d'anglais en ligne{" "}
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Репетиторы английского
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Online İngilizce kursu
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Engelse les online
                      </a>
                    </li>
                    <li class="styles_NewBlockLink__2vgZ4">
                      <a
                        href="#"
                        class="_Link_1vbbq_4 _Link--variant-accentDark_1vbbq_27"
                        data-preply-ds-component="Link"
                      >
                        Corsi di inglese online
                      </a>
                    </li>
                  </ul>
                </section>
              </div>

              <div class="table_avrage">
                <table class="styles_table__WaNg0">
                  <caption class="styles_sectionTitle__XMECF">
                    <h2
                      class="preply-ds-heading _Heading_15k8d_4 _Heading--variant-small_15k8d_411 _TextCentered_15gyg_2 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3"
                      data-preply-ds-component="Heading"
                    >
                      Prices for english lessons by tutor nationality
                    </h2>
                  </caption>

                  <thead>
                    <tr class="styles_headingRow__5H_uz">
                      <th>Country</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="styles_row__hJQZs">
                      <td class="styles_country__Djg6u">
                        <img
                          class="styles_flag__Sey6C"
                          src={require("../assets/flags/flah.png")}
                          alt="Flag"
                          style={{ width: "20px", objectFit: "contain" }}
                        />
                        <p
                          class="_Text_9tdt4_4 _Text--variant-s_9tdt4_28 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          Ukraine
                        </p>
                      </td>
                      <td class="styles_price__kcW8i">
                        <p
                          class="_Text_9tdt4_4 _Text--variant-default-semibold_9tdt4_76 _TextAccent_igxjd_3 _TextAccent--accent-branded_igxjd_36 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          $22 / hr
                        </p>
                      </td>
                    </tr>
                    <tr class="styles_row__hJQZs">
                      <td class="styles_country__Djg6u">
                        <img
                          class="styles_flag__Sey6C"
                          src={require("../assets/flags/united-kingdom.png")}
                          alt="Flag"
                          style={{ width: "20px", objectFit: "contain" }}
                        />
                        <p
                          class="_Text_9tdt4_4 _Text--variant-s_9tdt4_28 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          United Kingdom
                        </p>
                      </td>
                      <td class="styles_price__kcW8i">
                        <p
                          class="_Text_9tdt4_4 _Text--variant-default-semibold_9tdt4_76 _TextAccent_igxjd_3 _TextAccent--accent-branded_igxjd_36 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          $22 / hr
                        </p>
                      </td>
                    </tr>
                    <tr class="styles_row__hJQZs">
                      <td class="styles_country__Djg6u">
                        <img
                          class="styles_flag__Sey6C"
                          src={require("../assets/flags/united-states.png")}
                          alt="Flag"
                          style={{ width: "20px", objectFit: "contain" }}
                        />
                        <p
                          class="_Text_9tdt4_4 _Text--variant-s_9tdt4_28 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          United States of America
                        </p>
                      </td>
                      <td class="styles_price__kcW8i">
                        <p
                          class="_Text_9tdt4_4 _Text--variant-default-semibold_9tdt4_76 _TextAccent_igxjd_3 _TextAccent--accent-branded_igxjd_36 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          $22 / hr
                        </p>
                      </td>
                    </tr>
                    <tr class="styles_row__hJQZs">
                      <td class="styles_country__Djg6u">
                        <img
                          class="styles_flag__Sey6C"
                          src={require("../assets/flags/united-kingdom.png")}
                          alt="Flag"
                          style={{ width: "20px", objectFit: "contain" }}
                        />
                        <p
                          class="_Text_9tdt4_4 _Text--variant-s_9tdt4_28 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          Philippines
                        </p>
                      </td>
                      <td class="styles_price__kcW8i">
                        <p
                          class="_Text_9tdt4_4 _Text--variant-default-semibold_9tdt4_76 _TextAccent_igxjd_3 _TextAccent--accent-branded_igxjd_36 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          $22 / hr
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table class="styles_table__WaNg0">
                  <caption class="styles_sectionTitle__XMECF">
                    <h2
                      class="preply-ds-heading _Heading_15k8d_4 _Heading--variant-small_15k8d_411 _TextCentered_15gyg_2 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3"
                      data-preply-ds-component="Heading"
                    >
                      Prices for english lessons by tutor nationality
                    </h2>
                  </caption>

                  <thead>
                    <tr class="styles_headingRow__5H_uz">
                      <th>Native/non native speakers</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="styles_row__hJQZs">
                      <td class="styles_country__Djg6u">
                        {/* <!-- <img class="styles_flag__Sey6C" src="images/ukrain.jpg" alt="Flag"> --> */}
                        <p
                          class="_Text_9tdt4_4 _Text--variant-s_9tdt4_28 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          Native english speakers
                        </p>
                      </td>
                      <td class="styles_price__kcW8i">
                        <p
                          class="_Text_9tdt4_4 _Text--variant-default-semibold_9tdt4_76 _TextAccent_igxjd_3 _TextAccent--accent-branded_igxjd_36 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          $22 / hr
                        </p>
                      </td>
                    </tr>
                    <tr class="styles_row__hJQZs">
                      <td class="styles_country__Djg6u">
                        {/* <!-- <img class="styles_flag__Sey6C" src="images/United-Kingdom.jpg" alt="Flag"> --> */}
                        <p
                          class="_Text_9tdt4_4 _Text--variant-s_9tdt4_28 _TextAccent_igxjd_3 _TextAccent--accent-default_igxjd_3 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          Non native english speakers
                        </p>
                      </td>
                      <td class="styles_price__kcW8i">
                        <p
                          class="_Text_9tdt4_4 _Text--variant-default-semibold_9tdt4_76 _TextAccent_igxjd_3 _TextAccent--accent-branded_igxjd_36 _Color_y6u7n_3 _TextCentered_15gyg_2"
                          data-preply-ds-component="Text"
                        >
                          $22 / hr
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="botm_content_text">
                <h3 class="small_head">
                  Find an Online English Teacher to Help You Master English
                </h3>
                <p>
                  Are you trying to become fluent in English? Do you need to
                  improve your English skills for school, work or relocation? On
                  Preply, you get access to a range of online English tutors for
                  private lessons.
                </p>

                <h3 class="small_head">
                  How do online English lessons with Preply work?
                </h3>
                <p>
                  Booking and following a lesson with an online English tutor at
                  Preply is easy:
                </p>
                <p>
                  <strong>1. Choose a tutor:</strong> You can use filters in
                  "Find tutors" section to narrow the search results for your
                  needs and budget range. You can choose based on speciality
                  needed, specify the price range, prefered time, etc. You can
                  even filter tutors by nationality or other languages they
                  speak. If you have any questions for a tutor, you can message
                  them directly.
                </p>
                <p>
                  <strong>2. Book your first lesson:</strong> When you've found
                  a tutor, you can book your first lesson at a time and day that
                  works for you. During your first lesson you will discuss your
                  needs and goals with your private English tutor.
                </p>
                <p>
                  <strong>3. Buy a lesson package:</strong> If you enjoyed your
                  first lesson and want to continue learning with this tutor,
                  you can buy a lesson package and schedule online English
                  lessons for the coming weeks.
                </p>
                <p>
                  <strong>4. During and after your lessons:</strong> Lessons
                  will take place online in Preply Space, our own video platform
                  for effective online learning. After your private English
                  lesson the tutor might give you a summary of the class or give
                  you a homework assignment.
                </p>
                <p>
                  <strong>
                    5. Confirming your lessons and leaving a review:{" "}
                  </strong>
                  After the lesson, the platform will automatically send you a
                  request to confirm the lesson took place and leave a review.
                  If you don't do this within 72-hours the lesson will be
                  confirmed automatically.
                </p>

                <h3 class="small_head">
                  How Preply Can Help You Learn English Online
                </h3>
                <p>
                  People looking to improve their English language skills have
                  many options, so why should you use Preply? Well, our
                  first-class English teachers have experience teaching at every
                  level. No matter where you are on your journey to learning
                  English — absolute beginner or more advanced — you will be
                  able to find the right private English tutor to meet your
                  personal needs. Additionally, they all go through a background
                  check to ensure their credentials are legitimate and that you
                  get the highest quality online English lessons. Moreover, our
                  platform is convenient and easy to use, thereby making the
                  task of connecting with an English tutor simple. You can
                  contact an English tutor, book lessons on our site, then use
                  our video platform Preply Space for effective online learning.
                </p>
                <h3 class="small_head">
                  Find an Online English Teacher to Help You Master English
                </h3>
                <p>
                  Are you trying to become fluent in English? Do you need to
                  improve your English skills for school, work or relocation? On
                  Preply, you get access to a range of online English tutors for
                  private lessons.
                </p>

                <h3 class="small_head">Why learn English with Preply?</h3>
                <p>
                  Booking and following a lesson with an online English tutor at
                  Preply is easy:
                </p>
                <p>
                  <li>
                    <strong>Native English speakers:</strong> English lessons
                    are tailored to your needs and your specific stage of
                    learning, whether you want to learn conversational or
                    business English. Rather than be stuck in a classroom with a
                    bunch of other students, you get one-on-one time with an
                    English language expert and will see a faster progression in
                    your skills.
                  </li>
                </p>
                <p>
                  <li>
                    <strong>A personal approach:</strong>
                    When you've found a tutor, you can book your first lesson at
                    a time and day that works for you. During your first lesson
                    you will discuss your needs and goals with your private
                    English tutor.
                  </li>
                </p>
                <p>
                  <li>
                    <strong>Convenience: </strong> Online English lessons can
                    take place wherever and whenever you need them. Schedule
                    them with your English tutor to fit around your other
                    commitments, such as work or school. You can take an online
                    English lesson in the comforts of home, rather than rushing
                    through traffic to get to a lesson in town.
                  </li>
                </p>
                <p>
                  <li>
                    <strong>Affordable:</strong> All our virtual English
                    teachers offer an hourly rate. The price depends on level of
                    experience, qualifications and other factors; this allows
                    you to choose someone who fits your budget. You can take
                    some initial lessons with different tutors to find the one
                    to match your requirements and budget perfectly.
                  </li>
                </p>
                <p>
                  <li>
                    <strong>Several thousand experts:</strong>Preply is the
                    platform of choice for a massive number of online English
                    teachers as we are a trusted learning website. We have a
                    wide selection of experts so you will be able to find a
                    tutor whose teaching style works for you. They each have
                    reviews from previous students and a star rating. You can
                    read through the feedback before committing to an English
                    lesson to get a good idea of their teaching methods and
                    techniques. This helps you determine if the online tutor is
                    the right fit before you even make contact to ensure you
                    find the best English teacher possible.
                  </li>
                </p>
                <p>
                  <li>
                    <strong>Tutors you can trust: </strong>All our tutors go
                    through a background check to ensure their credentials are
                    legitimate and that you get the highest quality online
                    English lessons.
                  </li>
                </p>

                <p>
                  <li>
                    <strong>There's a tutor on Preply for everyone: </strong>
                    Our first-class virtual English teachers have experience
                    teaching at every level. No matter where you are on your
                    journey to learning English — absolute beginner or more
                    advanced — you will be able to find the right English tutor
                    to meet your personal needs. Preply has private English
                    tutors from many different countries, so if you are a
                    complete beginner, you can find a tutor who also speaks your
                    native language
                  </li>
                </p>
                <h3 class="small_head">
                  Money-Back Guarantee for All Online English Lessons
                </h3>
                <p>
                  For your peace of mind, Preply guarantees 100% money back if
                  you're not happy with the selected instructor. We have so much
                  confidence in our vetted English tutors that if you don't
                  think they provided a great first English lesson, we will
                  refund your payment.
                </p>
                <p>
                  Want to learn more about how Preply can help you with learning
                  English? Chat with one of our friendly support team members
                  via email or the message board on the website.
                </p>
              </div>

              <div class="botm_content_faq">
                <h3 class="small_head">Frequently Asked Questions (FAQ)</h3>

                <h2 class="sm_head" itemprop="name">
                  🗣 How hard is English, and how many hours will it take to
                  become proficient?
                </h2>
                <p>
                  English is much easier than most other languages despite its
                  tense varieties, irregular verbs and pronunciation. With
                  regular online English lessons, you can start having basic
                  conversations quite quickly. If you want to be fluent, you’ll
                  need between 600-750 hours of 1-on-1 English tutoring with an
                  online teacher combined with self-practice. Hundreds of
                  experts are ready to help you improve. Choose an English tutor
                  who’s a native speaker now.
                </p>

                <h2 class="sm_head" itemprop="name">
                  📲 How do interactive online English lessons via video work?
                </h2>
                <p>
                  Tutors and students share files, note down vocabulary, share
                  their screens and chat on Preply Space, a video chat platform.
                  With it, you can have private English lessons anywhere with
                  online English tutors from all over the world.
                </p>

                <h2 class="sm_head" itemprop="name">
                  🎓 What are the most popular English tests?
                </h2>
                <p>
                  IELTS, TOEFL, SAT, ACT, and GRE are the top five standardized
                  tests for students and non-native English learners among
                  hundreds of English language tests worldwide. Data is based on
                  the annual number of test-takers and confirmed by the number
                  of lessons with online test prep tutors on Preply. IELTS is
                  accepted by most American, Australian, British, Canadian,
                  Irish, and New Zealand academic institutions, and many more
                  across the world. In particular, Australia, New Zealand,
                  Canada, and the United Kingdom use IELTS scores as proof of
                  prospective immigrants' competence in English. More
                  specifically, IELTS uses British English and is generally
                  favored by the United Kingdom and institutions in Commonwealth
                  nations, such as New Zealand and Australia. On the other hand,
                  the TOEFL tests your ability to communicate in English in
                  academic settings. Administered by US-based organization the
                  Education Testing Service, TOEFL uses American English. It is
                  accepted by thousands of institutions across many countries,
                  including the UK, USA, and Australia, as well as all of the
                  world’s top 100 universities. It can also satisfy the required
                  language component for student, post-study, skilled migration,
                  and business visas in Australia, Canada, and New Zealand.
                </p>

                <h2 class="sm_head" itemprop="name">
                  🧑🏻‍🤝‍🧑🏿How many people speak English worldwide?
                </h2>
                <p>
                  Over one billion people around the world speak English, of
                  which about 379 million are native speakers.
                </p>

                <h2 class="sm_head" itemprop="name">
                  🌍 How many countries speak English?
                </h2>
                <p>
                  There are 67 countries around the world where English is the
                  official language, and it is a regional language in 27 other
                  countries.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FindTutor;
