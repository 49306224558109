import React, { useRef, useState } from "react";
import { LangunageIcon, SearchIcon } from "../utils/Icons";
import { Link } from "react-router";

const HelpPage = () => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div className="help-page-header">
        <div className="container">
          <div style={{ padding: "20px 0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link to={"/"} style={{ cursor: "pointer" }}>
                <img src={require("../assets/images/main_logo.svg").default} />
              </Link>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Link
                  to={"/"}
                  style={{
                    marginLeft: "10px",
                    fontWeight: 600,
                    color: "#000",
                    textDecoration: "none",
                  }}
                >
                  Go to Zalana
                </Link>
                <div
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LangunageIcon />
                  <div
                    style={{
                      marginLeft: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: 600,
                    }}
                  >
                    <div class="language-selector_sec">
                      <select>
                        <option value="/ar/" class="text-black">
                          English
                        </option>
                        <option value="/pt-BR/" class="text-black">
                          Português do Brasil
                        </option>
                        <option value="/nl/" class="text-black">
                          Nederlands
                        </option>
                        <option value="/en/" class="text-black" selected="">
                          English
                        </option>
                        <option value="/fr/" class="text-black">
                          Français
                        </option>
                        <option value="/de/" class="text-black">
                          Deutsch
                        </option>
                        <option value="/id/" class="text-black">
                          Bahasa Indonesia
                        </option>
                        <option value="/it/" class="text-black">
                          Italiano
                        </option>
                        <option value="/ja/" class="text-black">
                          日本語
                        </option>
                        <option value="/ko/" class="text-black">
                          한국어
                        </option>
                        <option value="/pl/" class="text-black">
                          Polski
                        </option>
                        <option value="/ro/" class="text-black">
                          Română
                        </option>
                        <option value="/ru/" class="text-black">
                          Pусский
                        </option>
                        <option value="/zh-CN/" class="text-black">
                          简体中文
                        </option>
                        <option value="/es/" class="text-black">
                          Español
                        </option>
                        <option value="/sv/" class="text-black">
                          Svenska
                        </option>
                        <option value="/th/" class="text-black">
                          ภาษาไทย
                        </option>
                        <option value="/tr/" class="text-black">
                          Türkçe
                        </option>
                        <option value="/uk/" class="text-black">
                          Українська
                        </option>
                        ;
                      </select>
                      <div class="down_arrow">
                        <img
                          src={
                            require("../assets/images/down_arrow.svg").default
                          }
                          alt="down_arrow"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: "100px",
                textAlign: "center",
                fontSize: "30px",
                fontWeight: 700,
                marginBottom: "20px",
              }}
            >
              Hello , how can we help you?
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  marginBottom: "20px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="search_block"
                  style={{
                    width: "50%",
                    position: "relative",
                  }}
                >
                  <input
                    onFocus={() => setIsActive(true)}
                    onBlur={() => setIsActive(false)}
                    placeholder="Search for articles..."
                    className={`help-input ${isActive ? " active" : ""} `}
                  />
                  <div
                    style={{ position: "absolute", top: "20px", left: "20px" }}
                  >
                    <SearchIcon color={isActive ? " gray" : "#000"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="container">
          <div className="articles-main-container">
            <div className="articles-containers">
              <div>
                <img
                  src={
                    "https://downloads.intercomcdn.com/i/o/564545/4261ecc0ca4e41f6b9a52f1c/fb5c81ed3a220004b71069645f112867.png"
                  }
                  style={{
                    width: "60px",
                    height: "60px",
                    objectFit: "contain",
                  }}
                />{" "}
              </div>
              <div
                style={{
                  margin: "5px 20px 5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="articles-title">Help for students</div>
                <div>56 articles</div>
              </div>
            </div>
            <div className="articles-containers">
              <div>
                <img
                  src={
                    "https://downloads.intercomcdn.com/i/o/564546/2bbe25c97793dfbdf8c9e010/4a47a0db6e60853dedfcfdf08a5ca249.png"
                  }
                  style={{
                    width: "60px",
                    height: "60px",
                    objectFit: "contain",
                  }}
                />{" "}
              </div>
              <div
                style={{
                  margin: "5px 20px 5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="articles-title">Help For Tutors</div>
                <div>62 articles</div>
              </div>
            </div>
            <div className="articles-containers">
              <div>
                {" "}
                <img
                  src={
                    "https://downloads.intercomcdn.com/i/o/564547/b1a6c03666072e278b447fbc/10fb15c77258a991b0028080a64fb42d.png"
                  }
                  style={{
                    width: "60px",
                    height: "60px",
                    objectFit: "contain",
                  }}
                />{" "}
              </div>
              <div
                style={{
                  margin: "5px 20px 5px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div className="articles-title">ZaLANA Business</div>
                <div>11 articles</div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "center",
              height: "200px",
            }}
          >
            <div>
              <Link to={"/"}>
                <img
                  src={require("../assets/images/main_logo.svg").default}
                  style={{ width: "200px", objectFit: "contain" }}
                />
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "50px",
              }}
            >
              <div style={{ color: "grey", fontWeight: 500 }}>
                Zalana © 2024
              </div>
              <div
                style={{
                  width: "80px",
                  margin: " 10px auto",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <a href="https://www.facebook.com/preply" target="_blank">
                  <img
                    src={require("../assets/images/social3.svg").default}
                    style={{ width: "16px", height: "16px" }}
                  />
                </a>
                <a href="https://www.twitter.com/preplycom" target="_blank">
                  <img
                    src={require("../assets/images/social1.svg").default}
                    style={{ width: "16px", height: "16px" }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/preplycom"
                  target="_blank"
                >
                  <img
                    src={require("../assets/images/social2.svg").default}
                    style={{ width: "16px", height: "16px" }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HelpPage;
