import React, { useState } from "react";
import Button from "../../components/Button";
import { Link } from "react-router";
import { AppleLogo, GoogleLogo, FacebookLogo } from "../../utils/Icons";
import Separator from "../../components/Seprator";
import InputField from "../../components/InputField";
import CheckBox from "../../components/CheckBox";

const TutorSignup = () => {
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(false);
  const [signupdata, setSignupdata] = useState({});

  const onChange = (e) => {
    let { name, value } = e.target;
    let _data = { ...signupdata };

    _data[name] = value;

    if (_data[name].trim() == "") {
      delete _data[name];
    }

    setSignupdata(_data);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    console.log(signupdata);
  };

  return (
    <div className="inner-page Login_page sign_in">
      <div className="small-container">
        <h3 className="login-title" >Sign up as a tutor</h3>
        <div className="social-buttons-container">
          <div className="social-button">
            <div className="logo">
              <GoogleLogo />
            </div>
            Continue with Google
          </div>
          <div className="social-button">
            <div className="logo">
              <FacebookLogo />
            </div>
            Continue with Facebook
          </div>
        </div>
        <Separator text={"or"} color={"lightgray"} />
        <form onSubmit={handleSignup}>
          <InputField
            value={signupdata["email"] || ""}
            name={"email"}
            type={"email"}
            label={"Email"}
            onChange={onChange}
            placeholder={"Your Email"}
          />
          <InputField
            value={signupdata["password"] || ""}
            name={"password"}
            type={"password"}
            label={"Password"}
            onChange={onChange}
            placeholder={"Your Password"}
          />
          <CheckBox
            value={remember}
            setValue={() => setRemember(!remember)}
            text={"Remember me"}
          />
          <Button text={"Get Started"} loading={loading} onClick={handleSignup} />
        </form>
        <div className="terms-container">
          <div>By clicking Continue or Sign up, you agree to Preply </div>{" "}
          <Link to={"/"} className="links">
            Terms of use
          </Link>{" "}
          including{" "}
          <Link to={"/"} className="links">
            {" "}
            Subscription Terms{" "}
          </Link>
          and{" "}
          <Link to={"/"} className="links">
            {" "}
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TutorSignup;
