import React from "react";
import { Link } from "react-router";

const Aboutus = () => {
  return (
    <div className="inner-page banr_atch">
      <div class="about_sec">
        <div class="container ">
          <div class="about_sec_row">
            <div class="abu_left_cont">
              <h1 class="head_1">
                Unlocking <br /> human <br /> potential.
              </h1>
              <p>
                We deliver the best human learning experience by empowering our
                learners and tutors to succeed.
              </p>
              <div class="btn_row">
                <Link to={"/teach/signup"}>Join Our Team</Link>
              </div>
            </div>

            <div class="abu_left_right">
              <div class="styles_imageContainer__k6WSR">
                <div class="styles-module_container__yaJag">
                  <img
                    aria-hidden="true"
                    class="styles-module_image__LAg3H styles-module_on__stMfj"
                    sizes="(min-width: 880px) 600px,260px"
                    src={require("../assets/images/howworks/imgpsh_fullsize_anim (1).png")}
                    width="600"
                    height="600"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="Investing_sec">
        <div class="container Investing_row">
          <div class="styles_imageContainer__zJRy4">
            <div
              class="styles-module_container__yaJag"
              data-preply-ds-component="RebrandStackedImage"
            >
              <img
                aria-hidden="true"
                class="styles-module_image__LAg3H styles-module_on__stMfj"
                sizes="(min-width: 880px) 360px,200px"
                src={require("../assets/images/howworks/imgpsh_fullsize_anim (2).png")}
                height="360"
                alt=""
              />
            </div>
          </div>

          <div class="Investing_right">
            <h1 class="sub_head">Investing in your progress</h1>
            <p>
              We believe learning with a great tutor is life changing. That's
              why we match online tutors from across the globe with learners,
              and empower them to create personalized live language classes with
              AI-powered tools and content. This is how we deliver progress,
              create engagement and keep our learners motivated.
            </p>
          </div>
        </div>
      </div>

      <div class="story_sec">
        <div class="container story_row">
          <h1 class="sub_head">The story of Preply</h1>
          <p>
            Each journey has its own unique story. And ours is one to share loud
            and proud (in every language, of course).
          </p>

          <div class="ready_step">
            <div class="styles_yearContainer__An_fk">
              <div
                class="An_fk_row"
                data-ds-gap="24"
                data-ds-nowrap="true"
                data-ds-padding="none"
                data-ds-direction='{"_":"column","medium-l":"row"}'
                data-ds-alignitems="start"
                data-preply-ds-component="LayoutFlex"
              >
                <div
                  class="left_block_colm"
                  data-ds-direction="row"
                  data-preply-ds-component="LayoutFlex"
                >
                  <div
                    class="styles-module_container__yaJag"
                    data-preply-ds-component="RebrandStackedImage"
                  >
                    <img
                      aria-hidden="true"
                      class="styles-module_image__LAg3H styles-module_on__stMfj"
                      src={require("../assets/images/howworks/imgpsh_fullsize_anim (4).jpg")}
                      width="150"
                      height="150"
                      alt="Ready, set, skyrocket "
                      style={{objectFit:'contain' , marginRight: '20px' }}

                    />
                  </div>
                </div>
                <div class="styles_headingWrapper__TAz6Z">
                  <div
                    class="An_fk_content"
                    data-ds-gap="8"
                    data-ds-direction="column"
                    data-preply-ds-component="LayoutFlex"
                  >
                    <div class="styles_square__a7oc0"></div>
                    <p
                      class="_Text--variant-large-regular_9tdt4_92"
                      data-preply-ds-component="Text"
                    >
                      2012 - 2015
                    </p>
                    <h4
                      class="_Heading--variant-large_15k8d_399"
                      data-preply-ds-component="Heading"
                    >
                      Ready, set, skyrocket{" "}
                    </h4>
                    <p
                      class="_Text--medium-l--variant-large-regular_9tdt4_961"
                      data-preply-ds-component="Text"
                    >
                      Soon after its creation, Preply grows at a remarkable rate
                      with 3,000 tutors in 58 countries. The Kyiv team grows to
                      15 Preplers and we graduate from Techstars Berlin.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="styles_yearContainer__An_fk">
              <div
                class="An_fk_row"
                data-ds-gap="24"
                data-ds-nowrap="true"
                data-ds-padding="none"
                data-ds-direction='{"_":"column","medium-l":"row"}'
                data-ds-alignitems="start"
                data-preply-ds-component="LayoutFlex"
              >
                <div
                  class="left_block_colm"
                  data-ds-direction="row"
                  data-preply-ds-component="LayoutFlex"
                >
                  <div
                    class="styles-module_container__yaJag"
                    data-preply-ds-component="RebrandStackedImage"
                  >
                    \
                    <img
                      aria-hidden="true"
                      class="styles-module_image__LAg3H styles-module_on__stMfj"
                      src={require("../assets/images/howworks/imgpsh_fullsize_anim (5).jpg")}
                      width="150"
                      height="150"
                      alt="Ready, set, skyrocket "
                      style={{objectFit:'contain' , marginRight: '20px'}}

                    />
                  </div>
                </div>
                <div class="styles_headingWrapper__TAz6Z">
                  <div
                    class="An_fk_content"
                    data-ds-gap="8"
                    data-ds-direction="column"
                    data-preply-ds-component="LayoutFlex"
                  >
                    <div class="styles_square__a7oc0"></div>
                    <p
                      class="_Text--variant-large-regular_9tdt4_92"
                      data-preply-ds-component="Text"
                    >
                      2016 - 2018
                    </p>
                    <h4
                      class="_Heading--variant-large_15k8d_399"
                      data-preply-ds-component="Heading"
                    >
                      Game changer{" "}
                    </h4>
                    <p
                      class="_Text--medium-l--variant-large-regular_9tdt4_961"
                      data-preply-ds-component="Text"
                    >
                      Preply raises a whopping $1.3M from investors like
                      Techstars, SMRK VCDigital and Arthur Kosten. The team
                      grows to 100 - that’s 7x growth in less than 3 years!
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="styles_yearContainer__An_fk">
              <div
                class="An_fk_row"
                data-ds-gap="24"
                data-ds-nowrap="true"
                data-ds-padding="none"
                data-ds-direction='{"_":"column","medium-l":"row"}'
                data-ds-alignitems="start"
                data-preply-ds-component="LayoutFlex"
              >
                <div
                  class="left_block_colm"
                  data-ds-direction="row"
                  data-preply-ds-component="LayoutFlex"
                >
                  <div
                    class="styles-module_container__yaJag"
                    data-preply-ds-component="RebrandStackedImage"
                  >
                    <img
                      aria-hidden="true"
                      class="styles-module_image__LAg3H styles-module_on__stMfj"
                      src={require("../assets/images/howworks/imgpsh_fullsize_anim (7).jpg")}
                      width="150"
                      height="150"
                      alt="Ready, set, skyrocket "
                      style={{objectFit:'contain' , marginRight: '20px'}}

                    />
                  </div>
                </div>
                <div class="styles_headingWrapper__TAz6Z">
                  <div
                    class="An_fk_content"
                    data-ds-gap="8"
                    data-ds-direction="column"
                    data-preply-ds-component="LayoutFlex"
                  >
                    <div class="styles_square__a7oc0"></div>
                    <p
                      class="_Text--variant-large-regular_9tdt4_92"
                      data-preply-ds-component="Text"
                    >
                      2019 - 2021
                    </p>
                    <h4
                      class="_Heading--variant-large_15k8d_399"
                      data-preply-ds-component="Heading"
                    >
                      Hola, Barcelona!{" "}
                    </h4>
                    <p
                      class="_Text--medium-l--variant-large-regular_9tdt4_961"
                      data-preply-ds-component="Text"
                    >
                      Preply opens a new office in sunny Barcelona and raises
                      $10M from investors like Point Nine Capital and
                      RTAventures. This is followed by another round of $35M
                      from investors like Full In Partners and Inovo Venture.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="styles_yearContainer__An_fk">
              <div
                class="An_fk_row"
                data-ds-gap="24"
                data-ds-nowrap="true"
                data-ds-padding="none"
                data-ds-direction='{"_":"column","medium-l":"row"}'
                data-ds-alignitems="start"
                data-preply-ds-component="LayoutFlex"
              >
                <div
                  class="left_block_colm"
                  data-ds-direction="row"
                  data-preply-ds-component="LayoutFlex"
                >
                  <div
                    class="styles-module_container__yaJag"
                    data-preply-ds-component="RebrandStackedImage"
                  >
                    <img
                      aria-hidden="true"
                      class="styles-module_image__LAg3H styles-module_on__stMfj"
                      src={require("../assets/images/howworks/imgpsh_fullsize_anim (8).jpg")}
                      width="150"
                      height="150"
                      alt="Ready, set, skyrocket "
                      style={{objectFit:'contain' , marginRight: '20px'}}
                    />
                  </div>
                </div>
                <div class="styles_headingWrapper__TAz6Z">
                  <div
                    class="An_fk_content"
                    data-ds-gap="8"
                    data-ds-direction="column"
                    data-preply-ds-component="LayoutFlex"
                  >
                    <div class="styles_square__a7oc0"></div>
                    <p
                      class="_Text--variant-large-regular_9tdt4_92"
                      data-preply-ds-component="Text"
                    >
                      2022
                    </p>
                    <h4
                      class="_Heading--variant-large_15k8d_399"
                      data-preply-ds-component="Heading"
                    >
                      Unstoppable{" "}
                    </h4>
                    <p
                      class="_Text--medium-l--variant-large-regular_9tdt4_961"
                      data-preply-ds-component="Text"
                    >
                      Preply closes a round of $50M from investors like OWL
                      Ventures, Diligent Capital and Hoxton Ventures, among
                      others. Preply Business expands, bringing in companies
                      like Unilever and Eventbrite.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="styles_yearContainer__An_fk">
              <div
                class="An_fk_row"
                data-ds-gap="24"
                data-ds-nowrap="true"
                data-ds-padding="none"
                data-ds-direction='{"_":"column","medium-l":"row"}'
                data-ds-alignitems="start"
                data-preply-ds-component="LayoutFlex"
              >
                <div
                  class="left_block_colm"
                  data-ds-direction="row"
                  data-preply-ds-component="LayoutFlex"
                >
                  <div
                    class="styles-module_container__yaJag"
                    data-preply-ds-component="RebrandStackedImage"
                  >
                    <img
                      aria-hidden="true"
                      class="styles-module_image__LAg3H styles-module_on__stMfj"
                      src={require("../assets/images/howworks/imgpsh_fullsize_anim (6).jpg")}
                      width="150"
                      height="150"
                      style={{objectFit:'contain' , marginRight: '20px'}}
                      alt="Ready, set, skyrocket "
                    />
                  </div>
                </div>
                <div class="styles_headingWrapper__TAz6Z">
                  <div
                    class="An_fk_content"
                    data-ds-gap="8"
                    data-ds-direction="column"
                    data-preply-ds-component="LayoutFlex"
                  >
                    <div class="styles_square__a7oc0"></div>
                    <p
                      class="_Text--variant-large-regular_9tdt4_92"
                      data-preply-ds-component="Text"
                    >
                      2023
                    </p>
                    <h4
                      class="_Heading--variant-large_15k8d_399"
                      data-preply-ds-component="Heading"
                    >
                      Practice makes possible{" "}
                    </h4>
                    <p
                      class="_Text--medium-l--variant-large-regular_9tdt4_961"
                      data-preply-ds-component="Text"
                    >
                      Preply raises an extension round of $70M led by Horizon
                      Capital. A complete rebranding takes the new brand
                      identity to another level, reinforcing, empowering and
                      truly showcasing Preply’s vision to unlock human potential
                      through language learning.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="leadership_sec">
        <h1 class="large_head">Our leadership team</h1>
        <div class="leadership_row container">
          <div class="leadership_colm">
            <div class="styles_photoWrapper__73I6l">
              <img
                src={require("../assets/images/Kirill.jpg")}
                alt="Kirill Bigai"
                class="styles_photo__1JHmZ"
              />
            </div>
            <div class="leader_bio">
              <h3 class="small_head">Kirill Bigai</h3>
              <p>Co-founder and CEO</p>
            </div>
          </div>
          <div class="leadership_colm">
            <div class="styles_photoWrapper__73I6l">
              <img
                src={require("../assets/images/Dmytro.jpg")}
                alt="Dmytro Voloshyn"
                class="styles_photo__1JHmZ"
              />
            </div>
            <div class="leader_bio">
              <h3 class="small_head">Dmytro Voloshyn</h3>
              <p>Co-founder and CTO</p>
            </div>
          </div>
          <div class="leadership_colm">
            <div class="styles_photoWrapper__73I6l">
              <img
                src={require("../assets/images/Sofia.jpg")}
                alt="Sofia Tavares"
                class="styles_photo__1JHmZ"
              />
            </div>
            <div class="leader_bio">
              <h3 class="small_head">Sofia Tavares</h3>
              <p>Chief Brand Officer</p>
            </div>
          </div>
          <div class="leadership_colm">
            <div class="styles_photoWrapper__73I6l">
              <img
                src={require("../assets/images/Josh.jpg")}
                alt="Kirill Bigai"
                class="styles_photo__1JHmZ"
              />
            </div>
            <div class="leader_bio">
              <h3 class="small_head">Josh Crossick</h3>
              <p>Chief Product Officer</p>
            </div>
          </div>
          <div class="leadership_colm">
            <div class="styles_photoWrapper__73I6l">
              <img
                src={require("../assets/images/Keiran.jpg")}
                alt="Keiran Dodd"
                class="styles_photo__1JHmZ"
              />
            </div>
            <div class="leader_bio">
              <h3 class="small_head">Keiran Dodd</h3>
              <p>VP of People</p>
            </div>
          </div>
          <div class="leadership_colm">
            <div class="styles_photoWrapper__73I6l">
              <img
                src={require("../assets/images/Deepti.png")}
                alt="Deepti Sahi"
                class="styles_photo__1JHmZ"
              />
            </div>
            <div class="leader_bio">
              <h3 class="small_head">Deepti Sahi</h3>
              <p>VP of Product</p>
            </div>
          </div>
          <div class="leadership_colm">
            <div class="styles_photoWrapper__73I6l">
              <img
                src={require("../assets/images/Toni.png")}
                alt="Toni Sfeir"
                class="styles_photo__1JHmZ"
              />
            </div>
            <div class="leader_bio">
              <h3 class="small_head">Toni Sfeir</h3>
              <p>VP of B2B</p>
            </div>
          </div>
        </div>
      </div>

      <div class="Our-principles">
        <div class="container">
          <h1 class="large_head">Our principles</h1>
          <div class="Ambition_row">
            <h1 class="large_head color_pink">Ambition</h1>
            <p>
              drives us to transform the lives of our customers, and make a
              lasting impact on the world.
            </p>
          </div>
          <div class="Ambition_row_grid">
            <div class="text_colm">
              <h3 class="small_head">Care to change the world</h3>
              <p>
                We are passionate about our work and care deeply about its
                impact to be life changing.
              </p>
            </div>
            <div class="text_colm">
              <h3 class="small_head">We do it for learners</h3>
              <p>
                For both Preply and tutors, learners are why we do what we do.
                Every day we focus on empowering tutors to deliver an
                exceptional learning experience.
              </p>
            </div>
            <div class="text_colm">
              <h3 class="small_head">Keep perfecting</h3>
              <p>
                To create an outstanding customer experience, we focus on
                simplicity, smoothness, and enjoyment, continually perfecting it
                as every detail matters.
              </p>
            </div>
          </div>

          <div class="Ambition_row">
            <h1 class="large_head color_2">Boldness</h1>
            <p>
              empowers our courageous actions, fuelling our hunger for impact
              and success.
            </p>
          </div>
          <div class="Ambition_row_grid">
            <div class="text_colm">
              <h3 class="small_head">Now is the time</h3>
              <p>
                In a fast-paced world, it matters how quickly we act. Now is the
                time to make great things happen.
              </p>
            </div>
            <div class="text_colm">
              <h3 class="small_head">Disciplined Execution</h3>
              <p>
                What makes us disciplined is the excellence in our execution. We
                set clear goals, focus on what matters, and utilize our
                resources efficiently.
              </p>
            </div>
            <div class="text_colm">
              <h3 class="small_head">Dive deep</h3>
              <p>
                We leverage business acumen and curiosity to investigate
                disparities between numbers and stories, unlocking meaningful
                insights to guide our decisions.{" "}
              </p>
            </div>
          </div>

          <div class="Ambition_row">
            <h1 class="large_head color_3">Collaboration</h1>
            <p>
              unites us as an extraordinary team, embracing growth and placing
              team success above all.{" "}
            </p>
          </div>
          <div class="Ambition_row_grid Ambition_grid_4">
            <div class="text_colm">
              <h3 class="small_head">Growth mindset</h3>
              <p>
                We proactively seek growth opportunities and believe today's
                best performance becomes tomorrow's starting point. We humbly
                embrace feedback and learnings from setbacks.
              </p>
            </div>
            <div class="text_colm">
              <h3 class="small_head">Raise the bar</h3>
              <p>
                We raise our performance standards continuously, alongside each
                new hire and promotion. We build diverse and high-performing
                teams that can make a real difference.
              </p>
            </div>
            <div class="text_colm">
              <h3 class="small_head">Challenge, disagree & commit</h3>
              <p>
                We value open and candid communication, even when we don’t fully
                agree. We speak our minds, challenge when necessary, and fully
                commit to decisions once made.{" "}
              </p>
            </div>
            <div class="text_colm">
              <h3 class="small_head">One Preply</h3>
              <p>
                We prioritize collaboration, inclusion, and the success of our
                team over personal ambitions. Together, we support and celebrate
                each other's progress.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="Our-principles the news">
        <div class="container">
          <h1 class="large_head">Preply in the news</h1>

          <div class="Ambition_row_grid">
            <div class="text_colm">
              <div class="blog-img">
                <img
                  src={require("../assets/images/blog_1.png")}
                  style={{ height: "auto" }}
                />
              </div>
              <span class="date">August 29, 2023</span>
              <p>
                “After this most recent $70 million Series C extension, Preply's
                total funding sits at around $170 million”
              </p>
              <img src={require("../assets/images/about/1.png")} />
            </div>

            <div class="text_colm">
              <div class="blog-img">
                <img
                  src={require("../assets/images/blog_2.png")}
                  style={{ height: "auto" }}
                />
              </div>
              <span class="date">April 15, 2020</span>
              <p>
                For both Preply and tutors, learners are why we do what we do.
                Every day we focus on empowering tutors to deliver an
                exceptional learning experience.
              </p>
              <img src={require("../assets/images/about/3.png")} />
            </div>

            <div class="text_colm">
              <div class="blog-img">
                <img
                  src={require("../assets/images/blog_3.png")}
                  style={{ height: "auto" }}
                />
              </div>
              <span class="date">April 1, 2021</span>
              <p>
                To create an outstanding customer experience, we focus on
                simplicity, smoothness, and enjoyment, continually perfecting it
                as every detail matters.
              </p>
              <img src={require("../assets/images/about/2.png")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
